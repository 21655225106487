import React, { FC, memo, SyntheticEvent, useCallback, useState } from 'react'
import clsx from 'clsx'
import { If } from 'boards-web-ui'

import { MessageAttachments } from '@features/updates/components/MessageAttachments/MessageAttachments'
import useBoard from '@hooks/useBoard'
import { useOnMount } from '@ui/hooks/useOnMount'
import { useAuth } from '@hooks/useAuth'
import { useToast } from '@hooks/useToast'
import { useUpdatesTranslations } from '@features/updates/hooks/useUpdatesTranslations'
import { useUpdatesMessage } from '@features/updates/hooks/useUpdatesMessage'
import { useUpdatesEvents } from '@features/analytics/useUpdatesEvents'

import Grid from '../../../../elements/Grid'
import { UiUpdatesMessage } from '../../../models'
import UpdatesMessageHeader from './UpdatesMessageHeader'
import UpdatesMessageBody from './UpdatesMessageBody'
import UpdatesMessageSeenBy from './UpdatesMessageSeenBy'
import styles from './UpdatesMessage.module.css'

export enum UpdatesSourceEnum {
  Feed = 'message_feed',
  Screen = 'message_screen',
  Preview = 'message_content_preview',
}

type Props = {
  message: UiUpdatesMessage
  className?: string
  presentationMode?: boolean
  onClick?: (event: SyntheticEvent) => void
  source: UpdatesSourceEnum
}

const UpdatesMessage: FC<Props> = ({
  message,
  className,
  presentationMode = false,
  onClick,
  source,
}) => {
  const { isEditor, isOwnBoard } = useBoard()
  const { user } = useAuth()
  const toast = useToast()
  const { toastSent } = useUpdatesTranslations()

  const { read, count } = useUpdatesMessage(message?.id)
  const { messageView } = useUpdatesEvents()
  const [postedJustNow, setPostedJustNow] = useState(
    message?.unread && presentationMode,
  )

  const onMount = useCallback(() => {
    if (!presentationMode) {
      messageView(message?.node, message?.id, count || 0)
    }

    if (message?.unread && !presentationMode) {
      read()
    }

    if (postedJustNow && user?.uid === message.sender.id) {
      toast(toastSent(), true, 300)
      const timer = setTimeout(() => {
        setPostedJustNow(false)
        clearTimeout(timer)
      }, 1000)
    }
  }, [
    toast,
    toastSent,
    postedJustNow,
    message,
    presentationMode,
    read,
    count,
    user,
    messageView,
  ])

  useOnMount(onMount)
  const messageAttachment = message.processedContent

  return (
    <div
      className={clsx([
        styles.Root,
        presentationMode && styles.Hovered,
        message?.unread && presentationMode && styles.Unread,
        postedJustNow && styles.PostedJustNow,
        className,
      ])}
      onClick={onClick}
    >
      <UpdatesMessageHeader
        presentationMode={presentationMode}
        boardTitle={message?.title}
        author={message?.sender?.properties?.name}
        icon={message?.icon}
        message={message}
      />

      <UpdatesMessageBody
        title={message?.properties?.title}
        text={message?.properties?.text}
        presentationMode={presentationMode}
      />

      {messageAttachment && (
        <MessageAttachments
          messageAttachment={messageAttachment}
          message={message}
          source={source}
          onSelectMessage={onClick}
        />
      )}
      <If state={isEditor || isOwnBoard}>
        <Grid justifyContent="space-between" alignSelf="flex-end">
          <UpdatesMessageSeenBy count={message ? message.viewCount : 0} />
        </Grid>
      </If>
    </div>
  )
}

export default memo(UpdatesMessage)
