import {
  MessageAttachment,
  MessageAttachmentNodeSize,
  MessageAttachmentNodeSizes,
  NodeType,
} from '../../../models'

type LayoutConfig = {
  sizes: Partial<MessageAttachmentNodeSizes>
  defaultSize: MessageAttachmentNodeSize
  columnSpacing: number
  rowSpacing: number
  deletedWidth: number
  deletedHeight: number
}

const DEFAULT_ATTACHMENT_PREVIEW_SIZE_SINGLE: MessageAttachmentNodeSize = {
  width: 200,
  height: 200,
}

const DEFAULT_ATTACHMENT_PREVIEW_SIZE_MULTIPLE: MessageAttachmentNodeSize = {
  width: 162,
  height: 162,
}

const MESSAGE_ATTACHMENT_PREVIEW_SIZES_SINGLE: Partial<MessageAttachmentNodeSizes> =
  {
    [NodeType.IMAGE]: {
      width: 334,
      height: 323,
    },
    [NodeType.LINK]: {
      width: 334,
      height: 94,
    },
    [NodeType.PDF]: {
      width: 334,
      height: 323,
    },
    [NodeType.PAGE]: {
      width: 334,
      height: 323,
    },
    [NodeType.TEXT]: {
      width: 334,
      height: 94,
    },
    [NodeType.VIDEO_STREAMING]: {
      width: 334,
      height: 216,
    },
    [NodeType.VIDEO]: {
      width: 334,
      height: 216,
    },
    [NodeType.FOLDER]: {
      width: 200,
      height: 94,
    },
    [NodeType.AUDIO]: {
      width: 334,
      height: 94,
    },
  }

const MESSAGE_ATTACHMENT_PREVIEW_SIZES_MULTIPLE: Partial<MessageAttachmentNodeSizes> =
  {
    [NodeType.IMAGE]: DEFAULT_ATTACHMENT_PREVIEW_SIZE_MULTIPLE,

    [NodeType.PDF]: DEFAULT_ATTACHMENT_PREVIEW_SIZE_MULTIPLE,

    [NodeType.PAGE]: DEFAULT_ATTACHMENT_PREVIEW_SIZE_MULTIPLE,

    [NodeType.LINK]: {
      width: 162,
      height: 77,
    },

    [NodeType.TEXT]: {
      width: 162,
      height: 77,
    },
    [NodeType.VIDEO_STREAMING]: {
      width: 162,
      height: 102,
    },
    [NodeType.VIDEO]: {
      width: 162,
      height: 102,
    },
    [NodeType.FOLDER]: {
      width: 162,
      height: 77,
    },
    [NodeType.AUDIO]: {
      width: 162,
      height: 77,
    },
  }

const BUILD_LAYOUT_MULTIPLE_CONFIG: LayoutConfig = {
  sizes: MESSAGE_ATTACHMENT_PREVIEW_SIZES_MULTIPLE,
  defaultSize: DEFAULT_ATTACHMENT_PREVIEW_SIZE_MULTIPLE,
  columnSpacing: 8,
  rowSpacing: 8,
  deletedWidth: 162,
  deletedHeight: 70,
}

const BUILD_LAYOUT_SINGLE_CONFIG: LayoutConfig = {
  sizes: MESSAGE_ATTACHMENT_PREVIEW_SIZES_SINGLE,
  defaultSize: DEFAULT_ATTACHMENT_PREVIEW_SIZE_SINGLE,
  columnSpacing: 16,
  rowSpacing: 16,
  deletedWidth: 334,
  deletedHeight: 70,
}

const getWidthAndHeight = (
  attachment: MessageAttachment,
  config: LayoutConfig,
) => {
  const isDeleted = attachment.isDeletedOrMoved
  if (isDeleted) {
    return {
      height: config.deletedHeight,
      width: config.deletedWidth,
    }
  }

  const { type } = attachment.node
  const size =
    config.sizes[type as keyof typeof config.sizes] || config.defaultSize

  return size
}

const createTiles = (
  messageAttachments: MessageAttachment[],
  config: LayoutConfig,
) => {
  const rowsHeights = [0, 0]

  const tiles = messageAttachments.map((attachment) => {
    const { width, height } = getWidthAndHeight(attachment, config)

    const column = rowsHeights[0] <= rowsHeights[1] ? 0 : 1
    const rowHeight = rowsHeights[column]

    const x = column * (width + config.columnSpacing)
    const y = rowHeight ? rowHeight + config.rowSpacing : 0

    rowsHeights[column] += rowsHeights[column]
      ? height + config.rowSpacing
      : height

    const rect = { x, y, width, height }

    return { node: attachment.node, rect }
  })

  return {
    height: Math.max(rowsHeights[0], rowsHeights[1]),
    tiles,
  }
}

const createMessageAttachmentTiles = (
  messageAttachments: MessageAttachment[],
) => {
  const isMultiple = messageAttachments.length > 1
  const config = isMultiple
    ? BUILD_LAYOUT_MULTIPLE_CONFIG
    : BUILD_LAYOUT_SINGLE_CONFIG

  return createTiles(messageAttachments, config)
}

export default createMessageAttachmentTiles
