import React, { PropsWithChildren } from 'react'
import { ButtonIcon, CloseIcon } from 'boards-web-ui'

import useBoard from '@hooks/useBoard'
import { IconWrapper } from '@ui/components/IconWrapper'
import { useReverseTrialContext } from '@features/reverseTrial/ReverseTrialContext'
import usePreviewNode from '@features/nodePreview/hooks/usePreviewNode'
import PreviewModalHeader from '@features/nodePreview/components/PreviewModalHeader'
import PreviewModalBody from '@features/nodePreview/components/PreviewModalBody'
import Grid from '../../../../elements/Grid'

import { ReactComponent as PrevIcon } from '../../nodePreview/icons/Prev.svg'
import { ReactComponent as NextIcon } from '../../nodePreview/icons/Next.svg'
import { Folder, NodeType } from '../../../models'
import styles from './PreviewModal.module.css'

type Props = PropsWithChildren<{
  controls: React.ReactElement
  folder: Folder
  nid: string
  prev: () => void
  next: () => void
  close: () => void
}>

const PreviewModal: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  controls,
  folder,
  nid,
  next,
  prev,
  close,
}) => {
  const { isPageBoard, isActive } = useBoard()
  const { isFreePlanUser } = useReverseTrialContext()
  const { node } = usePreviewNode({
    specificNid: nid,
    folder,
  })

  if (!node) return null

  const isLimitedPageNode = node.node.type === NodeType.PAGE && isFreePlanUser

  const shouldShowActivateButton =
    isLimitedPageNode && (isPageBoard || !isActive)

  return (
    <div className={styles.Root}>
      <PreviewModalHeader onClick={close}>
        <Grid
          rowGap={20}
          columnGap={20}
          height="38px"
          justifyContent="flex-start"
        >
          <ButtonIcon
            onClick={close}
            size={38}
            round
            variant={'white-transparent'}
          >
            <IconWrapper>
              <CloseIcon />
            </IconWrapper>
          </ButtonIcon>

          <span className={styles.Title}>{node.node.title}</span>
        </Grid>

        {controls}
      </PreviewModalHeader>

      <PreviewModalBody onClick={close} withScroll>
        <div onClick={(e) => e.stopPropagation()}>
          <ButtonIcon
            className={styles.PrevIcon}
            size={46}
            round
            onClick={prev}
            disabled={!prev || shouldShowActivateButton}
            variant={'white-transparent'}
          >
            <PrevIcon />
          </ButtonIcon>
        </div>

        {children}

        <div onClick={(e) => e.stopPropagation()}>
          <ButtonIcon
            className={styles.NextIcon}
            size={46}
            round
            onClick={next}
            disabled={!next || shouldShowActivateButton}
            variant={'white-transparent'}
          >
            <NextIcon />
          </ButtonIcon>
        </div>
      </PreviewModalBody>
    </div>
  )
}

export default PreviewModal
