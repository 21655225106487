import { FC } from 'react'
import { Trans } from 'react-i18next'
import {
  ContentPickersEnum,
  SelectAction,
  useContentNavigation,
  useContentSelection,
} from '@features/pickers'
import { PickerModal } from '@ui/components/PickerModal'

import { NodeType } from 'app/models'
import { SelectContentDialogBody } from './SelectContentDialogBody'
import { SelectContentDialogHeader } from './SelectContentDialogHeader'
import { SelectContentDialogFooter } from './SelectContentDialogFooter'

interface Props {
  onClose: () => void
  action: SelectAction
}

export const SourcePickerModal: FC<Props> = ({ onClose, action }) => {
  const {
    nodes,
    clearSelection,
    insertSelection,
    toggleNode,
    showClearMsgIfHasSelectedNodes,
  } = useContentSelection(action)

  const { pickerFolderId } = useContentNavigation(
    ContentPickersEnum.SourcePicker,
  )

  return (
    <PickerModal
      onClose={onClose}
      picker={ContentPickersEnum.SourcePicker}
      header={
        <SelectContentDialogHeader
          category={<Trans i18nKey={'insert_to_page_select_from'} />}
        />
      }
      footer={
        <SelectContentDialogFooter
          selectedNodes={nodes.length}
          clearSelection={clearSelection}
          insertSelection={insertSelection}
        />
      }
      onBeforeGoBack={showClearMsgIfHasSelectedNodes}
      scrollKey={pickerFolderId}
    >
      <SelectContentDialogBody
        nodes={nodes}
        toggleNode={toggleNode}
        beforeSelectFolder={showClearMsgIfHasSelectedNodes}
        enabledNodeTypes={[
          NodeType.BUTTON,
          NodeType.CHECKOUT,
          NodeType.FOLDER,
          NodeType.FORM,
          NodeType.HEADLINE,
          NodeType.IMAGE,
          NodeType.LINK,
          NodeType.PAGE,
          NodeType.PARAGRAPH,
          NodeType.PDF,
          NodeType.PROFILE,
          NodeType.TEXT,
          NodeType.VIDEO,
          NodeType.VIDEO_STREAMING,
        ]}
        withRadioBtn
      />
    </PickerModal>
  )
}
