import { KeyboardEventHandler, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { blockKeyboardsKeysDecorator } from '@helpers/blockKeyboardsKeysDecorator'
import { lineBreaksRemovalFromPasteEventDecorator } from '@helpers/lineBreaksRemovalFromPasteEventDecorator'

import TextAreaResizable from '../../../../elements/TextAreaResizable'
import { EDIT_LINK_MODE } from './EditLink'

import styles from './EditLink.module.css'

type Props = {
  value: string
  step: EDIT_LINK_MODE
  error: string | null
  inputRef: React.RefObject<HTMLTextAreaElement>
  onChange: (value: string) => void
  onKeyDown: KeyboardEventHandler | undefined
}

const EditLinkTextArea = ({
  value,
  step,
  error,
  inputRef,
  onChange,
  onKeyDown,
}: Props) => {
  const { t } = useTranslation()
  const [editing, setEditing] = useState(true)

  const TextareaClass = editing ? styles.TextareaEditing : styles.Textarea

  return (
    <div className={styles.TooltipWrapper} onClick={(e) => e.stopPropagation}>
      {step === EDIT_LINK_MODE.PREVIEW && (
        <label className={styles.Label}>
          <Trans i18nKey="content_key_link" />
        </label>
      )}

      <TextAreaResizable
        inputRef={inputRef}
        className={TextareaClass}
        placeholder={t('link_paste_your_link_here')}
        rows={1}
        onKeyDown={blockKeyboardsKeysDecorator(onKeyDown)}
        onPaste={lineBreaksRemovalFromPasteEventDecorator()}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
          onChange(e.target.value)
        }
        onClick={() => setEditing(true)}
        onBlur={() => setEditing(false)}
        value={value}
        size={15}
      />
      {error && <p className={styles.ErrorMessage}>{error}</p>}
    </div>
  )
}

export default EditLinkTextArea
