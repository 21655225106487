import { Trans } from 'react-i18next'
import { Warning2Icon } from 'boards-web-ui'
import clsx from 'clsx'
import { IconWrapper } from '@ui/components/IconWrapper'
import styles from './MessageAttachmentNotFound.module.css'

const MessageAttachmentNotFound = ({
  isMultipleAttachments,
}: {
  isMultipleAttachments: boolean
}) => (
  <div
    className={clsx(styles.NotFound, isMultipleAttachments && styles.Multiple)}
  >
    <IconWrapper size={20} className={styles.MultipleIcon}>
      <Warning2Icon />
    </IconWrapper>

    <span className={styles.NotFoundText}>
      <Trans i18nKey="message_content_deleted" />
    </span>
  </div>
)

export default MessageAttachmentNotFound
