import { ActionCreator } from './type-helpers'

export const enum ACTION_TYPE {
  CREATE_INVITE_LINK_START = 'CREATE_INVITE_LINK_START',
  CREATE_INVITE_LINK_END = 'CREATE_INVITE_LINK_END',
}

const createInviteLinkStart = () => ({
  type: ACTION_TYPE.CREATE_INVITE_LINK_START,
})

const createInviteLinkEnd = () => ({
  type: ACTION_TYPE.CREATE_INVITE_LINK_END,
})

const actions = {
  createInviteLinkStart,
  createInviteLinkEnd,
} as const

export type TCreatePendingActions =
  | ActionCreator<
      typeof createInviteLinkStart,
      ACTION_TYPE.CREATE_INVITE_LINK_START
    >
  | ActionCreator<
      typeof createInviteLinkEnd,
      ACTION_TYPE.CREATE_INVITE_LINK_END
    >

export default actions
