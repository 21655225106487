import { QuerySnapshot } from 'firebase/firestore'
import { permissionActions } from '../../../actions'
import { AppDispatch } from '../../../app/store'
import { Permission } from '../../../app/models'
import { transformPermission } from '../transformers/PermissionTransformer'

type Props = {
  boardUid: string
  bid: string
  isFinal: boolean
  dispatch: AppDispatch
}

export const PermissionSnapshot =
  ({ boardUid, bid, isFinal, dispatch }: Props) =>
  (snapshot: QuerySnapshot) => {
    const boardId = `${boardUid}-${bid}`
    const items = {} as { [id: string]: Permission }

    snapshot.docChanges().forEach(({ doc, type }) => {
      const data = transformPermission(doc)

      if (type === 'added' || type === 'modified') {
        items[data.recipient] = data
      }

      if (type === 'removed') {
        dispatch(permissionActions.deletePermission(boardId, data.recipient))
      }
    })

    if (Object.keys(items).length > 0) {
      dispatch(permissionActions.setPermission(boardId, items, isFinal))
    }
  }
