import {
  ClipboardEventHandler,
  KeyboardEventHandler,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { AnimatedDialog, ButtonIcon, theme, useDialog } from 'boards-web-ui'
import { Trans } from 'react-i18next'

import CloseButtonWithTooltip from 'ui/components/CloseButtonWithTooltip'
import DoneButton from 'ui/components/DoneButton'
import EmojiPicker from 'components/EmojiPicker'
import ModalEditWithConfirmation from 'components/ModalEditWithConfirmation'
import { lineBreaksRemovalFromPasteEventDecorator } from '@helpers/lineBreaksRemovalFromPasteEventDecorator'
import { blockKeyboardsKeysDecorator } from '@helpers/blockKeyboardsKeysDecorator'

import { ReactComponent as SmileIcon } from '../../../../icons/emoji.svg'
import { NodeType } from '../../../models'
import useEditTextAPI from '../hooks/useNodeAPI'

import { ICreateOrUpdateNode } from '../models/IUseNodeCreatorAPI'

import styles from './EditHeadlineOrParagraph.module.css'
import useNodeDiscardChanges from '../hooks/useNodeDiscardChanges'

interface Props {
  initialText: string
  placeholder: string
  headline?: boolean
  paragraph?: boolean
  onSave: ICreateOrUpdateNode
  onKeyDown?: KeyboardEventHandler
  onPate?: ClipboardEventHandler
}

const EditHeadlineOrParagraph = ({
  initialText,
  placeholder,
  headline,
  paragraph,
  onSave,
  onKeyDown,
  onPate
}: Props) => {
  const inputRef = useRef<HTMLTextAreaElement>(null)
  const { close } = useDialog()
  const discardChanges = useNodeDiscardChanges()
  const [cursorPosition, setCursorPosition] = useState<number>()
  const [showEmojiPicker, setShowEmojiPicker] = useState(false)
  const { text, hasChanges, isEmpty, setText } = useEditTextAPI('', initialText)

  const node = useMemo(
    () => ({
      title: headline ? text : '',
      text: paragraph ? text : '',
      type: paragraph ? NodeType.PARAGRAPH : NodeType.HEADLINE,
    }),
    [text, paragraph, headline],
  )

  useEffect(() => {
    if (inputRef?.current) {
      inputRef.current.focus()
      inputRef.current.selectionStart = inputRef.current.value.length
      inputRef.current.selectionEnd = inputRef.current.value.length
    }
  }, [])

  const handleSmileIconClick = () => setShowEmojiPicker(true)

  const onClickOutsideEmojiPicker = () => {
    setCursorPosition(undefined)
    setShowEmojiPicker(false)
  }

  const onEmojiClick = (emoji: string) => {
    if (inputRef?.current) {
      const { selectionStart } = inputRef.current
      const position = cursorPosition || selectionStart

      if (!Number.isNaN(position)) {
        const textStr = text || ''
        const newVal =
          textStr.slice(0, position) + emoji + textStr.slice(position)
        setCursorPosition(position + emoji.length)
        setText(newVal)
      }
    }
  }

  const handleSave = () => {
    if (isEmpty) return

    if (hasChanges) {
      onSave(node)
    }

    close()
  }

  const handleClose = () => {
    if (hasChanges) {
      discardChanges()
      return
    }

    close()
  }

  const inputClasses = [styles.TextInput]
  if (paragraph) inputClasses.push(styles.Paragraph)
  if (headline) inputClasses.push(styles.Headline)

  return (
    <AnimatedDialog
      index={2}
      settings={{
        fullScreen: true,
        closeOnOverlayClick: false,
        hideParents: true,
        withoutAnimation: true,
        overlayBackgroundColor: theme.theme.colors.dialogBackgroundDark90,
        onEscape: close,
        onOverlayClick: handleSave,
      }}
      dialog={
        <ModalEditWithConfirmation
          headerControls={<CloseButtonWithTooltip onClose={handleClose} gray />}
          footerControls={
            <DoneButton disabled={isEmpty} onClick={handleSave}>
              <Trans i18nKey="action_done" />
            </DoneButton>
          }
        >
          <div className={styles.ContentWrapper}>
            <div
              className={`${styles.EmojiPickerWrapper} ${
                showEmojiPicker && styles.ShowPicker
              }`}
            >
              <ButtonIcon round size={38} variant="gray">
                <SmileIcon
                  className={styles.SmileIcon}
                  onClick={() => handleSmileIconClick()}
                />
              </ButtonIcon>

              {showEmojiPicker && (
                <EmojiPicker
                  onClickEmoji={onEmojiClick}
                  onClickOutside={onClickOutsideEmojiPicker}
                  side="right"
                  standalone
                />
              )}
            </div>
            <div className={styles.TextareaWrapper}>
              <textarea
                ref={inputRef}
                className={inputClasses.join(' ')}
                placeholder={placeholder}
                spellCheck="false"
                value={text}
                onKeyDown={blockKeyboardsKeysDecorator(onKeyDown)}
                onPaste={lineBreaksRemovalFromPasteEventDecorator(onPate)}
                onChange={(event) => {
                  setText(event.currentTarget.value)
                }}
              />
            </div>
          </div>
        </ModalEditWithConfirmation>
      }
    />
  )
}

export default EditHeadlineOrParagraph
