import { useSelector } from 'react-redux'
import { Trans, useTranslation } from 'react-i18next'
import { Icon, SpinnerIcon } from 'boards-web-ui'
import Box from '../../../../elements/Box'
import Checkbox from '../../../../elements/Checkbox'
import CopyLink from './CopyLink'

import useConfirmDialog from '../../../../hooks/useConfirmDialog'
import useBoard from '../../../../hooks/useBoard'
import useSharePublicLink from '../hooks/useSharePublicLink'
import useShareEvents from '../../analytics/useShareEvents'
import { useToast } from '../../../../hooks/useToast'
import { getIsCreatingInviteLink } from '../../../../selectors'

const InviteLink = () => {
  const { t } = useTranslation()
  const toast = useToast()
  const { isOwnBoard } = useBoard()
  const { createInviteLinkEvent, deactivateInviteLinkEvent } = useShareEvents()
  const {
    publicKey,
    publicKeyEnabled,
    createOrEnableInviteLink,
    toogleInviteLink,
  } = useSharePublicLink()
  const confirmDialog = useConfirmDialog()
  const isCreatingInviteLink = useSelector(getIsCreatingInviteLink)
  const isToggleOn = isCreatingInviteLink || publicKeyEnabled

  const onToggleCheckbox = () => {
    if (!publicKeyEnabled) {
      createOrEnableInviteLink().catch((error) => {
        toast(error.message)
      })
      createInviteLinkEvent()
      return
    }

    confirmDialog({
      title: t('deactivate_invite_link'),
      message: t('anyone_who_tries_to_use'),
      confirmText: t('deactivate_link'),
      cancelText: t('action_cancel'),
      onConfirm: () => {
        toogleInviteLink().catch((error) => {
          toast(error.message)
        })
        deactivateInviteLinkEvent()
      },
    })
  }

  if (!isOwnBoard && !publicKeyEnabled) {
    return null
  }

  return (
    <>
      {isOwnBoard && (
        <Box
          mb={12}
          sx={{ display: 'flex', alignItems: 'center', height: '25px' }}
        >
          <Checkbox
            checked={isToggleOn}
            onChange={onToggleCheckbox}
            disabled={isCreatingInviteLink}
          >
            {isToggleOn ? (
              <Trans i18nKey={'share_screen_placeholder_anyone'} />
            ) : (
              <Trans i18nKey={'share_screen_placeholder'} />
            )}
          </Checkbox>
          {isCreatingInviteLink ? (
            <Icon size="22px" color="black">
              <SpinnerIcon style={{ marginLeft: '10px' }} />
            </Icon>
          ) : undefined}
        </Box>
      )}

      <CopyLink code={publicKey} active={publicKeyEnabled} />
    </>
  )
}

export default InviteLink
