import React, {
  ClipboardEventHandler,
  KeyboardEventHandler,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from 'react'
import { blockKeyboardsKeysDecorator } from '@helpers/blockKeyboardsKeysDecorator'
import { lineBreaksRemovalFromPasteEventDecorator } from '@helpers/lineBreaksRemovalFromPasteEventDecorator'
import TextAreaResizable from './TextAreaResizable'
import EmojiPicker from '../components/EmojiPicker'
import { ReactComponent as SmileIcon } from '../icons/emoji.svg'
import styles from './InputModalTitle.module.css'

interface Props {
  value: string
  placeholder: string
  className?: string
  onChange: undefined | ((text: string) => void)
  onKeyDown?: KeyboardEventHandler
  onPate?: ClipboardEventHandler
}

const InputModalTitle = ({
  className,
  value,
  placeholder,
  onChange,
  onKeyDown,
  onPate
}: Props) => {
  const inputRef = useRef<HTMLTextAreaElement>(null)
  const [showEmojiPicker, setShowEmojiPicker] = useState(false)
  const [cursorPosition, setCursorPosition] = useState(0)

  useEffect(() => {
    inputRef?.current?.focus()
  }, [])

  const handleFocus = (e: SyntheticEvent) => {
    let range
    let selection
    if (document.createRange) {
      // Firefox, Chrome, Opera, Safari, IE 9+
      range = document.createRange() // Create a range (a range is a like the selection but invisible)
      range.selectNodeContents(e.currentTarget) // Select the entire contents of the element with the range
      range.collapse(false) // collapse the range to the end point. false means collapse to end rather than the start
      selection = window.getSelection() // get the selection object (allows you to change selection)
      if (selection) {
        selection.removeAllRanges() // remove any selections already made
        selection.addRange(range) // make the range you have just created the visible selection
      }
    }
  }

  const handle = () => {
    setShowEmojiPicker(true)
  }

  const handleOnChange = (text: string) => {
    if (onChange) {
      onChange(text)
    }
  }

  const onClickOutsideEmojiPicker = () => {
    setCursorPosition(0)
    setShowEmojiPicker(false)
  }

  const onEmojiClick = (emoji: string) => {
    const title = value || ''

    const position = cursorPosition || inputRef?.current?.selectionStart || 0
    const newVal = title.slice(0, position) + emoji + title.slice(position)
    setCursorPosition(position + emoji.length)
    handleOnChange(newVal)
  }

  return (
    <div
      className={`${styles.Wrapper} ${className} ${
        showEmojiPicker ? styles.ShowPicker : ''
      }`}
    >
      <div className={styles.TextAreaResizableWrapper}>
        <TextAreaResizable
          className={styles.Input}
          inputRef={inputRef}
          onFocus={handleFocus}
          onKeyDown={blockKeyboardsKeysDecorator(onKeyDown)}
          onPaste={lineBreaksRemovalFromPasteEventDecorator(onPate)}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleOnChange(e.target.value)
          }
          placeholder={placeholder}
          value={value}
          size={24}
        />
      </div>
      <div
        className={[
          `${styles.EmojiPickerWrapper} ${
            showEmojiPicker ? styles.ShowPicker : ''
          }`,
        ].join(' ')}
      >
        <SmileIcon className={styles.SmileIcon} onClick={() => handle()} />
        {showEmojiPicker && (
          <EmojiPicker
            standalone
            onClickEmoji={onEmojiClick}
            onClickOutside={onClickOutsideEmojiPicker}
            side="right"
          />
        )}
      </div>
    </div>
  )
}

export default InputModalTitle
