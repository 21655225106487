import React, {
  MutableRefObject,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'

import {
  AnimatedDialog,
  AttachIcon,
  ButtonIcon,
  ButtonNew,
  CloseSmallIcon,
  eEnvironment,
  If,
  InputField,
  InputTextarea,
  Node,
  PremiumCrownIcon,
  theme,
  Tooltip,
  TooltipContent,
  TooltipPositions,
} from 'boards-web-ui'
import { Trans, useTranslation } from 'react-i18next'

import CloseButtonWithTooltip from '@ui/components/CloseButtonWithTooltip'
import useEditTextAPI from '@features/nodeEditor/hooks/useNodeAPI'
import { BoardInfo } from '@features/updates/components/ComposeModal/BoardInfo'
import { IconWrapper } from '@ui/components/IconWrapper'
import useBoard from '@hooks/useBoard'
import { useProfileName } from '@features/profile/hooks/useProfileName'
import { useComposePicker } from '@features/pickers/hooks/useComposePicker'
import FolderListItem from '@features/board/components/FolderListItem'
import { buildLayout } from '@features/board/helpers/layout'
import useConfirmDialog from '@hooks/useConfirmDialog'
import usePageNodeTransformer from '@features/nodes/hooks/usePageNodeTransformer'
import { useAttachContentToMessagesEvents } from '@features/analytics/useAttachContentToMessagesEvents'
import useBodyResizeHandler from '@ui/hooks/useBodyResizeHandler'
import { newKey } from '@helpers/NodeIdGenerator'
import { useReverseTrialContext } from '@features/reverseTrial/ReverseTrialContext'
import usePostMessage from '@features/updates/hooks/usePostMessage'
import usePaywall from '@hooks/usePaywall'
import { PaywallSourceEnum } from '@features/analytics/useAccountSubscriptionEvents'
import { useToast } from '@hooks/useToast'
import { useUpdatesTranslations } from '@features/updates/hooks/useUpdatesTranslations'
import { useAdjustHeightMessageTextarea } from './useAdjustHeightMessageTextarea'
import { ModalEditWithConfirmationAndTitle } from '../../../../../components/ModalEditWithConfirmation'
import styles from './ComposeModal.module.css'

import { Node as NodeModel } from '../../../../models'

interface Props {
  withOpenContentPicker?: boolean
  onCloseDialog: () => void
  onOpenConfirmModal: (params: { onPostMessage: () => void }) => void
}

const MAX_MODAL_HEIGHT = 800
const HEADER_HEIGHT = 48
const FOOTER_HEIGHT = 70
const PADDING_BUFFER = 50
const MAX_ATTACHMENT_COUNT = 15

export const ComposeModal = ({
  onCloseDialog,
  onOpenConfirmModal,
  withOpenContentPicker = false,
}: Props) => {
  const { t } = useTranslation()
  const { name } = useProfileName()

  const { title, text, setText, setTitle, hasChanges } = useEditTextAPI('', '')
  const [attachments, setAttachments] = useState<NodeModel[]>([])

  const ref = useRef() as MutableRefObject<HTMLInputElement>
  const { board, totalBoardMembersCount } = useBoard()
  const { postMessage } = usePostMessage()
  const { contentAttachClickEvent, contentAttachedEvent } =
    useAttachContentToMessagesEvents()

  const key = newKey()

  const countAttachments = attachments.length
  const hasAttachments = Boolean(countAttachments)

  const { selectContent } = useComposePicker({
    initiallyOpenPicker: withOpenContentPicker,
    maxItemsCount: MAX_ATTACHMENT_COUNT - countAttachments,
    preselectedNodesIds: attachments.map(({ id }) => id),
    action: ({ nodes }) => {
      setAttachments((prevState) => [...prevState, ...nodes])

      contentAttachedEvent({
        boardId: board?.id || '',
        fileType: nodes.map(({ type }) => type),
      })
    },
  })

  const handleAttachButtonClick = () => {
    selectContent()
    contentAttachClickEvent({ boardId: board?.id || '' })
  }

  const nodes = hasAttachments ? buildLayout(attachments) : undefined

  const handleRemoveAttachment = (nodeId: string) => () => {
    setAttachments((prevState) => prevState.filter(({ id }) => id !== nodeId))
  }

  const confirmDialog = useConfirmDialog()

  const handleCloseModal = () => {
    if (hasChanges || hasAttachments) {
      confirmDialog({
        title: t('discard_changes_title'),
        message: t('discard_changes_body'),
        cancelText: t('activate_dialog_button_cancel'),
        confirmText: t('action_discard'),
        onConfirm: onCloseDialog,
      })

      return
    }

    onCloseDialog()
  }

  const transformPageNode = usePageNodeTransformer()

  const contentRef = useRef<HTMLDivElement>(null)

  const [maxPopupHeight, setMaxPopupHeight] = useState<number | undefined>(
    undefined,
  )
  useBodyResizeHandler((_, height) => {
    if (height && height < MAX_MODAL_HEIGHT + PADDING_BUFFER * 2) {
      setMaxPopupHeight(height - PADDING_BUFFER * 2)
    }
  }, true)

  const [modalHeight, setModalHeight] = useState('620px')

  function adjustHeight() {
    if (!contentRef.current) {
      return
    }

    const height = contentRef.current.clientHeight

    setModalHeight(
      `${height + HEADER_HEIGHT + FOOTER_HEIGHT + PADDING_BUFFER}px`,
    )
  }

  useLayoutEffect(adjustHeight, [text, attachments, title])
  const { isFreePlanUser } = useReverseTrialContext()
  const { openPaywall } = usePaywall()

  const toast = useToast()
  const { toastSent } = useUpdatesTranslations()

  const handlePostMessage = useCallback(() => {
    postMessage({
      title,
      text,
      attachments: hasAttachments ? attachments : [],
      keyId: key,
    })
    onCloseDialog()

    toast(toastSent(), true, 300)
  }, [
    attachments,
    hasAttachments,
    key,
    onCloseDialog,
    postMessage,
    text,
    title,
    toast,
    toastSent,
  ])

  const handlePostClick = useCallback(() => {
    if (isFreePlanUser) {
      openPaywall({ source: PaywallSourceEnum.POST_MESSAGE })
      return
    }

    if (totalBoardMembersCount === 0) {
      handlePostMessage()
      return
    }

    onOpenConfirmModal({
      onPostMessage: handlePostMessage,
    })
  }, [
    handlePostMessage,
    isFreePlanUser,
    onOpenConfirmModal,
    openPaywall,
    totalBoardMembersCount,
  ])

  const isDisabledAttachButton = countAttachments >= MAX_ATTACHMENT_COUNT

  const { handleChange, textAreaRef } = useAdjustHeightMessageTextarea({
    onChange: setText,
  })

  return (
    <AnimatedDialog
      index={2}
      settings={{
        fullScreen: true,
        blankDialog: true,
        closeOnOverlayClick: false,
        closeOnEscapeButton: false,
        hideParents: true,
        withoutAnimation: true,
        overlayBackgroundColor: theme.theme.colors.dialogBackgroundDark90,
      }}
      dialog={
        <ModalEditWithConfirmationAndTitle
          ref={ref}
          headerControls={
            <div className={styles.HeaderRoot}>
              <div className={styles.Header}>
                <span className={styles.Title}>
                  <Trans i18nKey="message_input_text" />
                </span>

                <CloseButtonWithTooltip onClose={handleCloseModal} gray />
              </div>
            </div>
          }
          footerControls={
            <ButtonNew
              disabled={!text && !title}
              onClick={handlePostClick}
              startIcon={
                isFreePlanUser ? (
                  <IconWrapper>
                    <PremiumCrownIcon />
                  </IconWrapper>
                ) : null
              }
            >
              <Trans i18nKey="action_post" />
            </ButtonNew>
          }
          responsiveHeight
          withBorders
          className={styles.Modal}
          style={{ height: modalHeight, maxHeight: maxPopupHeight }}
        >
          <div className={styles.Content} ref={contentRef}>
            <BoardInfo icon={board?.icon} title={board?.title} name={name} />

            <div className={styles.Divider} />

            <div className={styles.Subject}>
              <InputField
                label={t('message_subject_title')}
                placeholder={t('message_subject_empty')}
                value={title}
                onChange={(event) => setTitle(event.target.value)}
                autoFocus
                maxLength={125}
                tabIndex={0}
                withEmojiPicker
              />
            </div>

            <div className={styles.Message}>
              <InputTextarea
                ref={textAreaRef}
                className={styles.TextArea}
                value={text}
                label={t('message_body_title')}
                placeholder={t('message_body_empty')}
                onChange={handleChange}
                tabIndex={0}
                withEmojiPicker
              />
            </div>

            <If state={hasAttachments}>
              <div className={styles.AttachmentsCount}>
                <Trans i18nKey={'message_compose_attachments_section'} /> (
                {countAttachments})
              </div>
            </If>

            {nodes?.tiles.length ? (
              <div
                className={styles.AttachmentsContainer}
                style={{ height: nodes?.height }}
              >
                {nodes.tiles.map(({ node, rect }, index) => (
                  <FolderListItem
                    key={node.id}
                    id={node.id}
                    rect={rect}
                    index={index}
                    className={styles.AttachmentListItem}
                  >
                    <>
                      <ButtonIcon
                        onClick={handleRemoveAttachment(node.id)}
                        size={32}
                        round
                        variant={'gray'}
                        className={styles.AttachmentRemove}
                      >
                        <IconWrapper size={16}>
                          <CloseSmallIcon />
                        </IconWrapper>
                      </ButtonIcon>

                      <Node
                        node={transformPageNode(node).node}
                        titleBreakLineThreshold={100}
                        environment={eEnvironment.Editor}
                      />
                    </>
                  </FolderListItem>
                ))}
              </div>
            ) : null}
            <Tooltip
              className={styles.AttachmentButtonTooltip}
              data={
                isDisabledAttachButton ? (
                  <TooltipContent
                    className={styles.AttachmentButtonTooltipContent}
                  >
                    <Trans i18nKey={'attach_content_limit'} />
                  </TooltipContent>
                ) : null
              }
              position={TooltipPositions.BOTTOM_LEFT}
            >
              <ButtonNew
                disabled={isDisabledAttachButton}
                color={'secondary'}
                size={'medium'}
                onClick={handleAttachButtonClick}
                startIcon={
                  <IconWrapper>
                    <AttachIcon />
                  </IconWrapper>
                }
              >
                <Trans i18nKey={'message_attach_content_button'} />
              </ButtonNew>
            </Tooltip>
          </div>
        </ModalEditWithConfirmationAndTitle>
      }
    />
  )
}
