import { PropsWithChildren } from 'react'
import { InputField } from 'boards-web-ui'
import ExplanationMessage from './ExplanationMessage'

import styles from './FormHeaderWithTitleAndMessage.module.css'

type Props = PropsWithChildren<{
  title: string
  inputLabel: string
  inputPlaceholder: string
  explanationText: string
  autoFocus?: boolean
  onTitleChange: (t: string) => void
}>
const FormHeaderWithTitleAndMessage = ({
  title,
  autoFocus,
  inputLabel,
  inputPlaceholder,
  explanationText,
  children,
  onTitleChange,
}: Props) => (
  <div className={styles.Root}>
    <InputField
      label={inputLabel}
      placeholder={inputPlaceholder}
      value={title}
      onChange={(event) => onTitleChange(event.target.value)}
      autoFocus={autoFocus}
      tabIndex={0}
      withEmojiPicker
      withResetButton
    />

    {children}
    <ExplanationMessage message={explanationText} />
  </div>
)

export default FormHeaderWithTitleAndMessage
