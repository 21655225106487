import { useEffect } from 'react'

import useInsightsEvents from '../analytics/useInsightsEvents'

import InsightsContent from './components/InsightsContent'

import ContainerReferenceProvider from './providers/ContainerReferenceProvider'

import './helper/MediaQueryPolyfill'

import styles from './Insights.module.css'

const Insights = () => {
  const { viewInsightsScreen } = useInsightsEvents()

  useEffect(() => {
    viewInsightsScreen()
    // Should be triggered only once
    // eslint-disable-next-line
  }, [])

  return (
    <div className={styles.Insights}>
      <ContainerReferenceProvider>
        <InsightsContent />
      </ContainerReferenceProvider>
    </div>
  )
}

export default Insights
