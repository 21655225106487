import { useSelectorFactory } from '@hooks/useSelectorFactory'
import useRouterParams from '../../../../hooks/useRouterParams'
import { getInvite } from '../../../../selectors'
import { updateBoardPublicKey } from '../../../../_firebase'
import useCreateInviteLink from './useCreateInviteLink'

const useSharePublicLink = () => {
  const { boardId, uid, bid } = useRouterParams()
  const invite = useSelectorFactory(getInvite, boardId)
  const publicKey = invite?.key || null
  const publicKeyEnabled = invite?.enabled || false
  const createInviteLink = useCreateInviteLink()

  const createOrEnableInviteLink = async () => {
    if (invite) {
      await toogleInviteLink()
      return
    }
    await createInviteLink({ uid, bid })
  }

  const toogleInviteLink = async () =>
    updateBoardPublicKey(uid, bid, !publicKeyEnabled)

  return {
    publicKey,
    publicKeyEnabled,
    createOrEnableInviteLink,
    toogleInviteLink,
  } as const
}

export default useSharePublicLink
