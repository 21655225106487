import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ProfilingForm } from 'boards-web-ui'
import { getQuestionsData } from '@features/profiling/data/profilingQuestions'
import useProfilingEvents from '@features/analytics/useProfilingEvents'
import useHandleAnswerSubmit from '@features/profiling/hooks/useHandleAnswerSubmit'
import {
  answerLeader,
  firstQuestion,
  ProfilingQuestionKeysEnum,
  ProfilingSubmitQAndA,
} from '@features/profiling/models'
import useProfilingAPI from '@features/profiling/hooks/useProfilingAPI'
import {
  convertSubmitDataToMarketingData,
  getUserPropertiesForAnalytics,
} from '@features/profiling/util/profilingHelper'
import { useAuth } from '@hooks/useAuth'
import { useAuthenticatedUserRedirect } from '@features/auth/hooks/useAuthenticatedUserRedirect'
import { updateUserProperties } from '../../../../api/AnalyticsAPI'
import styles from './ProfilingContent.module.css'

const ProfilingContent = () => {
  const { t } = useTranslation()
  const { user } = useAuth()

  const handleAnswerSubmit = useHandleAnswerSubmit()
  const { answeredTeamLead, complete } = useProfilingEvents()
  const { updateMarketingForm, saveMarketingForm } = useProfilingAPI()
  const questions = getQuestionsData(t)
  const [answer, setAnswer] = useState<ProfilingSubmitQAndA>()
  const { onProfilingCompleteRedirect } = useAuthenticatedUserRedirect()

  const handleSubmit = async (submitData: ProfilingSubmitQAndA) => {
    complete()
    const processedData = convertSubmitDataToMarketingData(submitData)
    saveMarketingForm(processedData)

    const analyticsData = getUserPropertiesForAnalytics(processedData)
    if (user) {
      updateUserProperties(user, { ...analyticsData })
    }

    onProfilingCompleteRedirect()
  }

  useEffect(() => {
    if (answer) {
      const answerKey = Object.keys(answer)
      const answerWithDetails = answerKey.map((item) => {
        if (answer[item] === answerLeader) {
          answeredTeamLead()
        }
        return {
          question: item,
          answer: answer[item],
        }
      })[0]
      if (answerWithDetails) {
        handleAnswerSubmit(answerWithDetails.question, answerWithDetails.answer)

        const marketingData = convertSubmitDataToMarketingData({
          [answerWithDetails.question]: answerWithDetails.answer,
        })
        const userProfileData = getUserPropertiesForAnalytics(
          marketingData,
          false,
        )
        updateMarketingForm(marketingData)
        if (user) {
          updateUserProperties(user, userProfileData)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answer])

  return (
    <div className={styles.Root}>
      <div>
        <ProfilingForm
          title={t('user_profiling_header_tell_us_about_yourself')}
          brandSearchPlaceholder={t(
            'profiling_industry_question_organization_name_placeholder',
          )}
          continueLabel={t('action_continue')}
          questions={questions}
          initialQuestion={firstQuestion}
          onSubmit={handleSubmit}
          onAnswerSubmitted={setAnswer}
          doCleanAnswers={(questionKey: string) => {
            return questionKey === ProfilingQuestionKeysEnum.INDUSTRY
          }}
        />
      </div>
    </div>
  )
}

export default ProfilingContent
