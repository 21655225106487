import React, { FC, memo } from 'react'
import { useTranslation } from 'react-i18next'
import { normalizeLinksInText, useTruncatedText } from 'boards-web-ui'

import styles from './UpdatesMessageBody.module.css'

type Props = {
  title: string
  text: string
  presentationMode: boolean
}

const MAX_LINES_MESSAGES = 3

export const UpdatesMessageBody: FC<Props> = ({
  title,
  text,
  presentationMode,
}) => {
  const parentRef = React.useRef<HTMLDivElement>(null)

  const { t } = useTranslation()
  const truncatedText = useTruncatedText({
    text,
    parentRef,
    maxLines: presentationMode ? MAX_LINES_MESSAGES : undefined,
    ellipsis: `... ${t('action_read_more')}`,
  })

  return (
    <div>
      {title && (
        <p
          className={styles.Title}
          dangerouslySetInnerHTML={{
            __html: normalizeLinksInText(title),
          }}
        />
      )}

      {text && (
        <p className={styles.Text} ref={parentRef} key={truncatedText}>
          <span
            dangerouslySetInnerHTML={{
              __html: normalizeLinksInText(truncatedText),
            }}
          />
          {presentationMode && (
            <>
              <span>... </span>
              <span className={styles.ReadMore}>{t('action_read_more')}</span>
            </>
          )}
        </p>
      )}
    </div>
  )
}

export default memo(UpdatesMessageBody)
