import React from 'react'

import useBoard from '../../../../hooks/useBoard'

import Board from '../../board/Board'
import FolderToolBar from '../../toolbar/FolderToolBar'
import ScrollArea from '../../../../ui/components/Scroll/ScrollArea'

import { ScrollAreaContainer } from '../../../../ui/models/ScrollArea'

import styles from './Content.module.css'

const Content: React.FC = () => {
  const { boardId, isFolderEmpty, isRoot, folder } = useBoard()

  if (!folder) {
    return null
  }

  return (
    <div className={styles.Root}>
      <FolderToolBar className={styles.Fixed} isPage={folder?.isPage} />
      <ScrollArea className={styles.Content} area={ScrollAreaContainer.FOLDER}>
        <Board
          boardId={boardId}
          isFolderEmpty={isFolderEmpty}
          folder={folder}
          isRoot={isRoot}
        />
      </ScrollArea>
    </div>
  )
}

export default Content
