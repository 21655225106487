import { useTranslation } from 'react-i18next'
import { PaywallDesktop, PaywallMobile } from 'boards-web-ui'

import ConfetisBackground from '@features/payments/components/ConfetisBackground'
import PageHeader from '@features/payments/components/PageHeader'
import SubscribeButtonText from '@features/payments/components/SubscribeButtonText'
import { useReverseTrialContext } from '@features/reverseTrial/ReverseTrialContext'
import { usePaywallTitle } from '@features/payments/hooks/usePaywallTitle'
import { usePaywallPlanTermSelection } from '@features/payments/hooks/usePaywallPlanTermSelection'
import usePrivatePaywall from '@features/payments/hooks/usePrivatePaywall'
import { usePaywallPlansBulletsProps } from '@features/payments/hooks/usePaywallPlansProps'
import { useProfileName } from '@features/profile/hooks/useProfileName'
import { useAuth } from '@hooks/useAuth'

import { isMobile } from '../../../utils/deviceParser'

const PrivatePaywall = () => {
  const { t } = useTranslation()
  const { user, signOut } = useAuth()
  const { name } = useProfileName()
  const {
    alreadySubscribedInAnotherStoreText,
    campaignId,
    current,
    isFromSource,
    isSubscribedToAvailablePlanFrom3rdSource,
    products,
    handlePlanSwipe,
    handleSubscribePlanClick,
  } = usePrivatePaywall()
  const { freePlanBullets, businessPlanBullets } = usePaywallPlansBulletsProps()
  const { shouldShowFreeTrialIndicator, shouldShowFreePlanIndicator } =
    useReverseTrialContext()

  const {
    termOptions,
    selectedProductId,
    billedText,
    isMonthlyTermSelected,
    currentPlan,
    discountForPlan,
    handleTermChange,
  } = usePaywallPlanTermSelection({
    items: products?.items,
    currencySymbol: products?.currencySymbol,
  })

  const { title: paywallTitle, isLongText } = usePaywallTitle({
    currentPlanTitle: currentPlan?.subscription?.title,
    shouldShowFreePlanIndicator,
    shouldShowFreeTrialIndicator,
  })

  const isCurrentSubscribedPlan = Boolean(
    current &&
      isSubscribedToAvailablePlanFrom3rdSource &&
      currentPlan?.priceId === current?.productId,
  )

  const Paywall = isMobile ? PaywallMobile : PaywallDesktop

  return (
    <ConfetisBackground
      isMobile={isMobile}
      hasConfetis={!!campaignId}
      header={
        <PageHeader
          name={name}
          isMobile={isMobile}
          user={user}
          hideBackButton={isFromSource}
          onLogoutClick={signOut}
        />
      }
    >
      {currentPlan && (
        <Paywall
          priceTermValue={selectedProductId}
          onTermChange={handleTermChange}
          planPriceTermSwitcherOptions={termOptions}
          onChangeSubscriptionPlan={handlePlanSwipe}
          title={paywallTitle}
          isLongTextTitle={isLongText}
          billedText={billedText}
          alreadySubscribedInAnotherStoreText={
            alreadySubscribedInAnotherStoreText || ''
          }
          defaultPlan={'business'}
          plans={{
            free: {
              chipLabel: t('free_plan_title'),
              planPriceTitle: t('free_plan_title'),
              planPriceValue: `${products?.currencySymbol}0`,
              bullets: freePlanBullets,
              confirmButtonText: current
                ? t('action_get_started')
                : t('paywall_current_plan'),
              hideSubscribeButton: !!current,
            },
            business: {
              chipLabel: null,
              planPriceTitle: currentPlan?.productName,
              planPriceValue: `${products?.currencySymbol}${currentPlan?.price}`,
              planPriceSubValue: {
                linedThrough: !isMonthlyTermSelected
                  ? `${products?.currencySymbol}${products?.items[0]?.price}`
                  : undefined,
                commonText: t('business_price'),
              },
              planPriceDiscountBadge: !isMonthlyTermSelected
                ? t('label_save_number', {
                    percentage: discountForPlan,
                  })
                : undefined,
              bullets: businessPlanBullets,
              confirmButtonText: (
                <SubscribeButtonText
                  plan={currentPlan}
                  subscribedPlanId={current?.productId}
                  showCurrentPlanText={isCurrentSubscribedPlan}
                />
              ),
              onSubscribePlan: () =>
                handleSubscribePlanClick(currentPlan, isCurrentSubscribedPlan),
            },
          }}
        />
      )}
    </ConfetisBackground>
  )
}

export default PrivatePaywall
