import { useLogEvents } from '@features/analytics/useLogEvents'
import { EventCategories } from '@features/analytics/models/logEvents'
import { useAuth } from '../../../hooks/useAuth'

enum TargetNames {
  FEATURE = 'update_feature',
  PREVIEW = 'preview_update',
  MESSAGE_FEED_SCREEN = 'messages_feed_screen',
  MESSAGE_SCREEN = 'message_screen',
  NEW = 'create_update',
  PRE_POST = 'post_update_needs_confirmation',
  MESSAGE_POSTED = 'messages_posted',
  ATTACH_CONTENT_CLICK = 'app_post_message_attach_content_click',
  MESSAGE_DELETE = 'app_message_delete',
  DELETE_CLICK = 'delete_update',
  MESSAGES_FREE_BANNER_CLICK = 'app_messages_free_banner_click',
  MESSAGES_OVER_BANNER_CLICK = 'app_messages_over_banner_click',
}

export const useUpdatesEvents = () => {
  const { user } = useAuth()
  const { trigger, buttonClick, screenView } = useLogEvents()

  const previewUpdateClick = () => buttonClick(TargetNames.PREVIEW)
  const attachContentClick = () => buttonClick(TargetNames.ATTACH_CONTENT_CLICK)
  const previewUpdate = () => screenView(TargetNames.PREVIEW)
  const previewUpdatesAll = (
    countMessages: number,
    countBoards: number,
    countUnread: number,
  ) =>
    screenView(TargetNames.MESSAGE_FEED_SCREEN, {
      count_messages: countMessages,
      count_boards: countBoards,
      count_unread: countUnread,
    })

  const openUpdatesClick = () => buttonClick(TargetNames.NEW)
  const openUpdates = () => buttonClick(TargetNames.NEW)

  const triggerPostUpdates = () => buttonClick(TargetNames.PRE_POST)
  const viewPostPanel = () => screenView(TargetNames.NEW)
  const bannerClick = () => screenView(TargetNames.NEW)

  const updateView = ({
    updateId,
    boardId,
    unreadState,
    isFileAttached,
    fileType,
    attachedKeyId,
  }: {
    updateId: string
    boardId: string
    unreadState: boolean
    isFileAttached: boolean
    fileType: string[]
    attachedKeyId: string | null
  }) => {
    screenView(TargetNames.MESSAGE_SCREEN, {
      key_attached_count: fileType.length,
      message_id: updateId,
      board_id: boardId,
      state: unreadState ? 'unread' : 'read',
      key_attached: `${isFileAttached}`,
      key_type: fileType,
      attached_key_id: attachedKeyId,
    })
  }

  const newMessageSend = ({
    boardId,
    value,
    title,
    body,
    membersCount,
    isFileAttached = false,
    fileType,
  }: {
    boardId: string
    value: number | string
    title: string
    body: string
    membersCount: number
    isFileAttached?: boolean
    fileType?: string[]
  }) => {
    trigger(TargetNames.MESSAGE_POSTED, {
      message_id: `${value}`,
      key_attached: `${isFileAttached}`,
      key_type: fileType,
      board_id: boardId,
      key_attached_count: fileType?.length,
      sender_id: user?.uid,
      title,
      body,
      members_count: membersCount,
      date: new Date().toISOString().replace('T', ' ').replace('Z', ''),
      platform: 'web',
    })
  }

  const messageDeleted = (updateId: string) =>
    trigger(TargetNames.MESSAGE_DELETE, { message_id: updateId })

  const messageView = (boardId: string, messageId: string, count: number) =>
    trigger(EventCategories.APP_MESSAGE_VIEW, {
      message_id: `${messageId}`,
      count,
    })

  const messageShowMoreClickEvent = () =>
    trigger(EventCategories.APP_MESSAGE_SHOW_MORE_CONTENT)

  const appStartWritingClick = ({ boardId }: { boardId: string }) =>
    trigger(EventCategories.APP_START_WRITE_MESSAGE_CLICK, {
      board_id: boardId,
    })

  const confirmationView = () =>
    trigger(EventCategories.APP_MESSAGE_POP_UP_VIEW)
  const sentToastShown = () =>
    trigger(EventCategories.APP_TOAST_UPDATE_SENT_SHOWN)
  const addNameShown = () => trigger(EventCategories.APP_POPUP_ADD_NAME_SHOWN)
  const addNameSaved = (name: string) =>
    trigger(EventCategories.APP_POPUP_ADD_NAME_SAVED, { name })
  const addNameDismissed = () =>
    trigger(EventCategories.APP_POPUP_ADD_NAME_DISMISSED)
  const deletePopUpUpdateShown = () =>
    trigger(EventCategories.APP_POPUP_DELETE_UPDATE_SHOWN)
  const deleteButtonCLick = () => buttonClick(TargetNames.DELETE_CLICK)
  const updatesPaneOpen = ({
    count,
    state,
  }: {
    count: number
    state: string
  }) =>
    trigger(EventCategories.UPDATES_PANE_OPEN, { count_messages: count, state })
  const updatesPaneClose = () => trigger(EventCategories.UPDATES_PANE_CLOSE)
  const messagesTooltipView = () =>
    trigger(EventCategories.APP_MESSAGES_TOOLTIP_VIEW)
  const messagesFreeBannerView = () =>
    trigger(EventCategories.APP_MESSAGES_FREE_BANNER_VIEW)
  const messagesOverBannerView = () =>
    trigger(EventCategories.APP_MESSAGES_OVER_BANNER_VIEW)
  const messagesFreeBannerCLick = () =>
    trigger(TargetNames.MESSAGES_FREE_BANNER_CLICK)
  const messagesOverBannerCLick = () =>
    trigger(TargetNames.MESSAGES_OVER_BANNER_CLICK)
  const boardShowInExtension = () =>
    trigger(EventCategories.APP_PAGES_BOARD_SHOW_IN_EXTENSION)
  const boardHideFromExtension = () =>
    trigger(EventCategories.APP_PAGES_BOARD_HIDE_FROM_EXTENSION)

  const appMessageContentShowInBoardClickEvent = (params: {
    messageId: string
    keyId: string
    boardId: string
  }) =>
    trigger(EventCategories.APP_MESSAGE_CONTENT_SHOW_IN_BOARD_CLICK, {
      message_id: params.messageId,
      board_id: params.boardId,
      key_id: params.keyId,
    })

  const appMessageContentClickEvent = (params: {
    messageId: string
    keyId: string
    boardId: string
    fileType: string[]
    isActiveBoard: boolean
  }) =>
    trigger(EventCategories.APP_MESSAGE_CONTENT_CLICK, {
      message_id: params.messageId,
      board_id: params.boardId,
      key_id: params.keyId,
      key_attached_count: 1,
      key_type: params.fileType,
      inactive_board: `${!params.isActiveBoard}`,
    })

  return {
    previewUpdateClick,
    previewUpdate,
    previewUpdatesAll,
    openUpdatesClick,
    openUpdates,
    triggerPostUpdates,
    viewPostPanel,
    messageView,
    confirmationView,
    newMessageSend,
    messageDeleted,
    bannerClick,
    updateView,
    sentToastShown,
    addNameShown,
    addNameSaved,
    addNameDismissed,
    deletePopUpUpdateShown,
    deleteButtonCLick,
    updatesPaneOpen,
    updatesPaneClose,
    messagesTooltipView,
    messagesFreeBannerView,
    messagesOverBannerView,
    messagesFreeBannerCLick,
    messagesOverBannerCLick,
    messageShowMoreClickEvent,
    boardShowInExtension,
    boardHideFromExtension,
    attachContentClick,
    appMessageContentShowInBoardClickEvent,
    appMessageContentClickEvent,
    appStartWritingClick,
  } as const
}
