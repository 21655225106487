import React, { FC, SyntheticEvent } from 'react'
import clsx from 'clsx'
import { eEnvironment, Node as NodeComponent } from 'boards-web-ui'

import FolderListItem from '@features/board/components/FolderListItem'
import usePageNodeTransformer from '@features/nodes/hooks/usePageNodeTransformer'
import { ITile } from '@features/board/models/nodes'
import { usePreviewAttachmentClick } from '@features/updates/hooks/usePreviewAttachmentClick'
import useBoard from '@hooks/useBoard'

import {
  MessageAttachment,
  NodeType,
  UiUpdatesMessage,
} from '../../../../models'
import MessageAttachmentNotFound from './MessageAttachmentNotFound'
import { UpdatesSourceEnum } from '../UpdatesMessage'
import MoreAttachmentsOverlay from './MoreAttachmentsOverlay'
import styles from './SingleAttachment.module.css'

type Props = {
  attachment: MessageAttachment
  layoutNode: ITile
  isMultipleAttachments: boolean
  message: UiUpdatesMessage
  source: UpdatesSourceEnum
  hiddenOverlayNumber?: number
  onSelectMessage?: (event: SyntheticEvent) => void
}

const getClassNameByType = (type: NodeType) => {
  switch (type) {
    case NodeType.IMAGE:
      return styles.AttachmentImage

    case NodeType.TEXT:
    case NodeType.LINK:
      return styles.AttachmentText

    case NodeType.VIDEO_STREAMING:
    case NodeType.VIDEO:
      return styles.AttachmentVideo

    case NodeType.PAGE:
      return styles.AttachmentPage

    case NodeType.FOLDER:
      return styles.AttachmentFolder

    case NodeType.PDF:
      return styles.AttachmentPdf

    case NodeType.AUDIO:
      return styles.AttachmentAudio

    default:
      return undefined
  }
}

export const SingleAttachment: FC<Props> = ({
  attachment,
  layoutNode,
  isMultipleAttachments,
  message,
  source,
  hiddenOverlayNumber,
  onSelectMessage,
}) => {
  const transformPageNode = usePageNodeTransformer()
  const { isActive } = useBoard()
  const handlePreviewAttachmentClick = usePreviewAttachmentClick({
    source,
    messageAttachment: attachment,
    message,
  })

  const { node, isDeletedOrMoved } = attachment

  const isNotFound = !node || isDeletedOrMoved

  return (
    <FolderListItem
      id={`${layoutNode.node.id}-message-attachment`}
      rect={layoutNode.rect}
      index={0}
      className={clsx([
        styles.AttachmentBorder,
        !isActive && styles.WithInactiveOverlay,
        node.type === NodeType.PDF && styles.PDFBorder,
      ])}
    >
      <div
        className={clsx(
          styles.Attachment,
          isMultipleAttachments && styles.Multiple,
          getClassNameByType(node.type),
        )}
        style={{ height: layoutNode.rect.height, width: layoutNode.rect.width }}
        onClick={(e: React.MouseEvent) => {
          e.stopPropagation()
          if (isNotFound) return

          handlePreviewAttachmentClick({
            node,
          })
        }}
      >
        {isNotFound ? (
          <MessageAttachmentNotFound
            isMultipleAttachments={isMultipleAttachments}
          />
        ) : (
          <NodeComponent
            node={transformPageNode(layoutNode.node).node}
            titleBreakLineThreshold={100}
            environment={eEnvironment.Editor}
            className={styles.AttachmentNode}
          />
        )}
      </div>
      {hiddenOverlayNumber && (
        <MoreAttachmentsOverlay
          hiddenOverlayNumber={hiddenOverlayNumber}
          onSelectMessage={onSelectMessage}
        />
      )}
    </FolderListItem>
  )
}
