export const SIGN_IN = '/signin'
export const SIGN_UP = '/signup'
export const SIGN_OUT = '/signout'
export const RESET_PASSWORD = '/reset-password'
export const EDITOR = '/'
export const MOBILE = '/mobile'
export const BOARDS = '/boards'
export const FOLDER = ':folderId'
export const NEW_PAGE = 'new-page'
export const EDIT_PAGE = 'edit-page/:pageId'
export const INSIGHTS = ':boardId/insights'
export const PROFILING = '/profiling'
export const PHONE_VERIFICATION = '/phone-verification'
export const WELCOME = '/welcome'
export const PLANS = '/plans'
export const ACCOUNT = '/account'
export const REDIRECT_PREFIX = '/$'
export const NOT_FOUND = '*'
export const USER_PERSONAL_INFO = 'personal-info'
export const USER_MESSAGE_PREFERENCES = 'email-preferences'
export const USER_SUBSCRIPTION = 'subscription'
export const ACADEMY = 'academy'

export const MESSAGES = 'messages'
export const PAGES = 'pages'
export const TEMPLATES = 'templates'

export const UNIVERSAL_LINK_KEEP_IN_WEB_SYMBOL = '/$/'

export type TAccountSubRoutes =
  | typeof USER_PERSONAL_INFO
  | typeof USER_SUBSCRIPTION
  | typeof USER_MESSAGE_PREFERENCES

// TODO move all Path params inside this object
export const PATH_PARAMS = {
  templateId: ':templateId',
  shortcutId: ':shortcutId',
  boardId: ':boardId',
  messageId: 'messageId',
}

// TODO move all Query params inside this ENUM
export enum QUERY_PARAMS {
  campaign = 'campaign',
  operation = 'operation',
  category = 'category',
  shortcutId = 'shortcutId',
  boardId = 'boardId',
  folderId = 'folderId',
  fromSource = 'from-source',
  copyBoardAllowed = 'copyBoardAllowed',
  showMessage = 'showMessage',
  message = 'message',
  cioLinkId = 'link_id',
  priceId = 'price-id',
  redirect = 'redirect',
}

export const QUERY_PARAM_VALUES = {
  operation: {
    createBoard: 'create-board',
    createPage: 'create-page',
  },
}

export const createFolderLink = (boardId: string, folderId: string): string =>
  `${PATH_PARAMS.boardId.replace(':boardId', boardId)}/${FOLDER.replace(
    ':folderId',
    folderId,
  )}`
export const createInsightsLink = (boardId: string): string =>
  INSIGHTS.replace(':boardId', boardId)
