import React, { FC, SyntheticEvent } from 'react'
import clsx from 'clsx'

import createMessageAttachmentTiles from '@features/updates/helpers/getLayoutMessageAttachment'

import { MessageAttachment, UiUpdatesMessage } from '../../../../models'
import { SingleAttachment } from './SingleAttachment'
import { UpdatesSourceEnum } from '../UpdatesMessage'
import styles from './MessageAttachments.module.css'

const MAX_MESSAGE_NUMBER = 4

type Props = {
  messageAttachment: MessageAttachment[]
  message: UiUpdatesMessage
  source: UpdatesSourceEnum
  onSelectMessage?: (event: SyntheticEvent) => void
}

export const MessageAttachments: FC<Props> = ({
  messageAttachment,
  message,
  source,
  onSelectMessage,
}) => {
  const isMultipleAttachments = messageAttachment.length > 1
  const shouldCutMessages =
    source === UpdatesSourceEnum.Feed &&
    messageAttachment.length > MAX_MESSAGE_NUMBER
  const attachmentsToShow = shouldCutMessages
    ? messageAttachment.slice(0, MAX_MESSAGE_NUMBER)
    : messageAttachment
  const layouts = createMessageAttachmentTiles(attachmentsToShow)
  const numberOfHiddenAttachments =
    messageAttachment.length - MAX_MESSAGE_NUMBER

  return (
    <div
      className={clsx(styles.Root, isMultipleAttachments && styles.Multiple)}
    >
      <div style={{ height: layouts.height }}>
        {attachmentsToShow.map((attachment, index) => {
          const layoutNode = layouts.tiles[index]
          const shouldlShowHiddenAttachments =
            index === 3 && source === UpdatesSourceEnum.Feed

          return (
            <SingleAttachment
              key={layoutNode.node.id}
              attachment={attachment}
              layoutNode={layoutNode}
              isMultipleAttachments={isMultipleAttachments}
              message={message}
              source={source}
              hiddenOverlayNumber={
                shouldlShowHiddenAttachments
                  ? numberOfHiddenAttachments
                  : undefined
              }
              onSelectMessage={onSelectMessage}
            />
          )
        })}
      </div>
    </div>
  )
}
