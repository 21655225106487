import useFolderEvents from '@features/analytics/useFolderEvents'
import { useEffect } from 'react'
import useBoard from '@hooks/useBoard'
import { useContentFromFeedActions } from '@features/updates/hooks/useContentFromFeedActions'
import { useUpdatesEvents } from '@features/analytics/useUpdatesEvents'
import { UpdatesSourceEnum } from '@features/updates/components/UpdatesMessage'
import { useUpdatesApi } from '@features/updates/hooks/useUpdatesApi'
import { useUpdatesMessages } from './useUpdatesMessages'

export const useOpenInBoardsClick = ({
  messageId,
  attachmentId,
  source,
}: {
  messageId: string
  attachmentId: string
  source: UpdatesSourceEnum
}) => {
  const { viewFolderEvent } = useFolderEvents()
  const { board } = useBoard()
  const { boardId } = useBoard()
  const { markAsRead } = useUpdatesApi()

  const { getMessageById } = useUpdatesMessages()
  const message = getMessageById(messageId)
  const messageAttachment = message?.processedContent?.find(
    (el) => el.node?.id === attachmentId,
  )

  const { appMessageContentShowInBoardClickEvent } = useUpdatesEvents()

  const { onOpenFolder, onClearTimerAfterScroll } = useContentFromFeedActions({
    folderId: messageAttachment?.folderId,
  })

  useEffect(() => {
    return () => onClearTimerAfterScroll()
  }, [onClearTimerAfterScroll])

  const handleOpenInBoardsClick = () => {
    if (!message || !messageAttachment) return

    markAsRead(message)

    viewFolderEvent({
      folderId: messageAttachment.folderId,
      boardId: board?.id || '',
      isActiveBoard: Boolean(board?.isActive),
      source,
      messageId: message.id,
    })

    onOpenFolder({
      folderId: messageAttachment.folderId,
      anchorId: attachmentId,
      source,
    })

    appMessageContentShowInBoardClickEvent({
      messageId: message.id,
      boardId,
      keyId: messageAttachment.node.id,
    })
  }

  return handleOpenInBoardsClick
}
