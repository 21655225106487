import { hash28 } from '@helpers/Base62'
import { DocumentData, QueryDocumentSnapshot } from 'firebase/firestore'

import {
  MessageProperties,
  UpdatesMessage,
  MessageAttachment,
  Node,
} from '../../../app/models'
import { transformNodeFolder } from './NodeFolderTransformer'

export const transformUpdateMessage = (
  doc: QueryDocumentSnapshot,
): UpdatesMessage => {
  const rawMessage = doc.data() as UpdatesMessage
  return {
    ...rawMessage,
    properties: transformMessageProperties(rawMessage.properties),
    processedContent: transformMessageContent(rawMessage),
  }
}

const transformMessageProperties = (
  properties: MessageProperties,
): MessageProperties => {
  return {
    ...properties,
    text: properties.text?.trim(),
  }
}

const getAttachmentNode = (
  id: string,
  message: UpdatesMessage,
): Node | null => {
  const generateNodes = message.content?.properties?.[id]
  if (!generateNodes) {
    return null
  }
  const propertyId = hash28(generateNodes.text || '')

  const properties = message.urls?.properties?.[propertyId]

  return transformNodeFolder(id, generateNodes, properties as DocumentData)
}

const transformMessageContent = (message: UpdatesMessage) => {
  if (!message.content || message.content?.ids?.length < 1) {
    return null
  }

  const result = message.content?.ids
    ?.map((id: string) => {
      const node = getAttachmentNode(id, message)
      const attachedFolder = message.content?.properties[id]?.folder
      return {
        folderId: attachedFolder,
        node,
        isDeletedOrMoved: !attachedFolder,
      }
    })
    .filter((attachment) => attachment.node !== null)

  if (!result || result.length === 0 || result[0] === null) {
    return null
  }

  return result as MessageAttachment[]
}
