import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useMemo } from 'react'
import useOverwriteRouteRedirect from '@hooks/useOverwriteRouteRedirect'
import useFeatureFlags from '@hooks/useFeatureFlags'
import {
  EDITOR,
  PHONE_VERIFICATION,
  PROFILING,
  QUERY_PARAMS,
  SIGN_IN,
  SIGN_UP,
  WELCOME,
} from '../../../constants/routes'
import { isMobile } from '../../../../utils/deviceParser'

export const useAuthenticatedUserRedirect = () => {
  const { state } = useLocation()
  const navigate = useNavigate()

  const { userProfilingFeatureFlag, phoneVerificationFeatureFlag } =
    useFeatureFlags()

  const [searchParams] = useSearchParams()

  const { routeHasRedirect, redirectToOverwriteRoute } =
    useOverwriteRouteRedirect()

  const originRedirectUrl = state?.originUrl

  const redirect = searchParams.get(QUERY_PARAMS.redirect)
  const shortcutId = searchParams.get(QUERY_PARAMS.shortcutId)
  const boardId = searchParams.get(QUERY_PARAMS.boardId)
  const folderId = searchParams.get(QUERY_PARAMS.folderId)

  return useMemo(() => {
    const doNavigationWithShortcutId = (pathName: string) => {
      if (shortcutId && boardId && folderId) {
        const queryParams = new URLSearchParams({
          [QUERY_PARAMS.shortcutId]: shortcutId,
          [QUERY_PARAMS.boardId]: boardId,
          [QUERY_PARAMS.folderId]: folderId,
        })

        navigate(`${pathName}?${queryParams}`, { state })

        return
      }

      navigate(pathName, { state })
    }

    const navigateToOriginUrl = () => {
      navigate(originRedirectUrl, { state: {}, replace: true })
    }

    const onSignInRedirect = () => {
      if (originRedirectUrl) {
        navigateToOriginUrl()
        return
      }

      if (routeHasRedirect(SIGN_IN)) {
        redirectToOverwriteRoute()
        return
      }

      if (isMobile) {
        navigate(WELCOME)
        return
      }

      if (redirect) {
        navigate(redirect)
        return
      }

      doNavigationWithShortcutId(EDITOR)
    }

    const onSignUpRedirect = () => {
      if (routeHasRedirect(SIGN_UP)) {
        redirectToOverwriteRoute()
        return
      }

      if (!phoneVerificationFeatureFlag.disabled) {
        doNavigationWithShortcutId(PHONE_VERIFICATION)
        return
      }

      if (userProfilingFeatureFlag) {
        doNavigationWithShortcutId(PROFILING)
        return
      }

      if (isMobile) {
        navigate(WELCOME)
        return
      }

      doNavigationWithShortcutId(EDITOR)
    }

    const onProfilingCompleteRedirect = () => {
      if (routeHasRedirect(PROFILING)) {
        redirectToOverwriteRoute()
        return
      }

      if (isMobile) {
        navigate(WELCOME)
        return
      }

      doNavigationWithShortcutId(EDITOR)
    }

    return {
      onSignInRedirect,
      onSignUpRedirect,
      onProfilingCompleteRedirect,
      originRedirectUrl,
    }
  }, [
    boardId,
    folderId,
    navigate,
    originRedirectUrl,
    phoneVerificationFeatureFlag.disabled,
    redirect,
    redirectToOverwriteRoute,
    routeHasRedirect,
    shortcutId,
    state,
    userProfilingFeatureFlag,
  ])
}
