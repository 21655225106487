import ValentineMarathonDialog from '@features/payments/components/ValentineMarathonDialog'
import { theme, useDialog } from 'boards-web-ui'
import { HOME } from 'constants/routes'
import { useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import styles from '../components/ValentineMarathonDialog.module.css'

const useMarathonCampaign = (campaignId?: string) => {
  const navigate = useNavigate()
  const { open } = useDialog()
  const redirectToHome = useCallback(() => navigate(HOME), [navigate])

  const isMarathonCampaign = campaignId === 'marathon_valentines25'

  useEffect(() => {
    if (isMarathonCampaign) {
      open(<ValentineMarathonDialog onClick={redirectToHome} />, {
        className: styles.Clear,
        fullScreen: false,
        overlayBackgroundColor: theme.theme.colors.dialogBackgroundDark90,
        withFullScreenDialogContainer: false,
        onCloseClick: redirectToHome,
      })
    }
  }, [isMarathonCampaign, redirectToHome, open])

  return { isMarathonCampaign }
}

export default useMarathonCampaign
