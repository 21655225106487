import { useCallback, useMemo } from 'react'
import { User } from '@firebase/auth'
import config from './config'
import { authorize, syncAuthState, unAuthorize } from './api'

export const useChromeExtensionMessageProvider = () => {
  const onAuthorized = useCallback(
    (accessTokenKey: string, url?: string): void => {
      if (!accessTokenKey) {
        throw new Error(
          `Token is Invalid - ${accessTokenKey}, should be not empty string`,
        )
      }
      authorize(config.id, accessTokenKey, url)
    },
    [],
  )

  const onUnAuthorized = useCallback((): void => {
    unAuthorize(config.id)
  }, [])

  const onSyncAuthState = useCallback((value: () => User | null,) => {
    return syncAuthState(config.id, value)
  }, [])

  return useMemo(() => ({
    onAuthorized,
    onUnAuthorized,
    onSyncAuthState
  }), [
    onAuthorized,
    onUnAuthorized,
    onSyncAuthState
  ])
}
