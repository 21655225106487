import { useDispatch, useSelector } from 'react-redux'
import { useCallback } from 'react'

import { commonActions, pendingActions } from '../../../../actions'
import { getAllInvites } from '../../../../selectors'
import {
  addBoardPublicKey,
  generateBoardPublicKey,
} from '../../../../_firebase'

interface GenerateKeyProps {
  defaultKey: string
}

interface CreateLinkArgs {
  uid: string
  bid: string
}

const useCreateInviteLink = () => {
  const dispatch = useDispatch()
  const invites = useSelector(getAllInvites)

  const createInviteLink = useCallback(
    async ({ uid, bid }: CreateLinkArgs) => {
      const inviteLink = `${uid}-${bid}`
      if (invites?.[inviteLink]) return

      try {
        dispatch(pendingActions.createInviteLinkStart())

        const data = await generateBoardPublicKey(uid, bid)
        const { defaultKey } = data as GenerateKeyProps
        addBoardPublicKey(uid, bid, defaultKey)

        dispatch(
          commonActions.setInviteLink(`${uid}-${bid}`, {
            key: defaultKey,
            enabled: true,
          }),
        )
      } finally {
        dispatch(pendingActions.createInviteLinkEnd())
      }
    },
    [dispatch, invites],
  )

  return createInviteLink
}

export default useCreateInviteLink
