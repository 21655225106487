import { FC, ReactElement } from 'react'

import { ContentPickersEnum, useContentNavigation } from '@features/pickers'
import { PickerModal } from '@ui/components/PickerModal'

import { SelectContentDialogBody } from './SelectContentDialogBody'
import { SelectContentDialogHeader } from './SelectContentDialogHeader'
import { Node, NodeType } from '../../../models'

interface Props {
  dialogCategory: string | ReactElement
  enabledNodeTypes: NodeType[]
  action: (node: Node) => void
  onClose: () => void
  onSelectBoard?: (boardId: string) => void
}

export const SourcePickerSingleSelectionModal: FC<Props> = ({
  dialogCategory,
  enabledNodeTypes,
  action,
  onClose,
  onSelectBoard,
}) => {
  const { pickerFolderId } = useContentNavigation(
    ContentPickersEnum.SourcePicker,
  )

  return (
    <PickerModal
      picker={ContentPickersEnum.SourcePicker}
      header={<SelectContentDialogHeader category={dialogCategory} />}
      onClose={onClose}
      scrollKey={pickerFolderId}
    >
      <SelectContentDialogBody
        nodes={[]}
        toggleNode={action}
        enabledNodeTypes={enabledNodeTypes}
        onSelectBoard={onSelectBoard}
      />
    </PickerModal>
  )
}
