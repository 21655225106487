import { SyntheticEvent } from 'react'
import { useUpdatesEvents } from '@features/analytics/useUpdatesEvents'
import styles from './MoreAttachmentOverlay.module.css'

type Props = {
  hiddenOverlayNumber: number
  onSelectMessage?: (event: SyntheticEvent) => void
}

const MoreAttachmentsOverlay = ({
  hiddenOverlayNumber,
  onSelectMessage,
}: Props) => {
  const { messageShowMoreClickEvent } = useUpdatesEvents()

  return (
    <div
      className={styles.MoreAttachmentsOverlay}
      onClick={(e: React.MouseEvent) => {
        e.stopPropagation()
        messageShowMoreClickEvent()
        onSelectMessage?.(e)
      }}
    >
      {`+${hiddenOverlayNumber}`}
    </div>
  )
}

export default MoreAttachmentsOverlay
