import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import React, { useCallback } from 'react'
import { PagesBoardIcon } from 'boards-web-ui'
import { getRootFolderId } from '@helpers/NodeIdGenerator'
import useBoard from '@hooks/useBoard'
import { ContentPickersEnum } from '@features/pickers'
import useFolder from '@hooks/useFolder'
import { AppState } from '../../../models/appState'
import { boardActions } from '../../../../actions'

export const useContentNavigation = (picker: ContentPickersEnum) => {
  const dispatch = useDispatch()
  const { boardId: pickerBoardId, folderId: pickerFolderId } = useSelector(
    (state: AppState) => state.app.pickersContentNavigation[picker],
  )

  const { t } = useTranslation()
  const { parentFolder, folder } = useFolder(pickerBoardId, pickerFolderId)
  const { isPageBoard } = useBoard({ boardId: pickerBoardId })

  const selectBoard = useCallback(
    (newBoardId?: string) => {
      dispatch(
        boardActions.setFolderPathAction(
          picker,
          newBoardId,
          newBoardId ? getRootFolderId(newBoardId) : '',
        ),
      )
    },
    [dispatch, picker],
  )

  const goBack = () => {
    if (pickerFolderId === getRootFolderId(pickerBoardId)) {
      dispatch(
        boardActions.setFolderPathAction(picker, undefined, pickerFolderId),
      )
      return
    }

    if (typeof parentFolder === 'string') {
      return
    }

    dispatch(
      boardActions.setFolderPathAction(
        picker,
        pickerBoardId,
        parentFolder?.id || getRootFolderId(pickerBoardId),
      ),
    )
  }

  const getTitle = () => {
    if (!pickerBoardId) {
      return t('label_my_boards')
    }
    if (folder?.isPage) {
      return folder?.title ? folder.title : t('page_my_page')
    }
    if (isPageBoard) {
      return (
        <>
          <PagesBoardIcon />
          &nbsp;{t('label_web_pages')}
        </>
      )
    }

    return folder?.title
      ? `${folder?.icon || ''} ${folder?.title}`
      : t('my_folder')
  }

  const canGoBack =
    picker === ContentPickersEnum.MovePicker ||
    picker === ContentPickersEnum.Compose
      ? pickerFolderId !== getRootFolderId(pickerBoardId)
      : pickerBoardId

  const cleanup = useCallback(() => {
    selectBoard('')
  }, [selectBoard])

  return {
    pickerBoardId,
    pickerFolderId,
    title: getTitle(),
    isPage: folder?.isPage || false,
    goBack: canGoBack ? goBack : undefined,
    selectBoard,
    selectFolder: (newFolderId: string) =>
      dispatch(
        boardActions.setFolderPathAction(picker, pickerBoardId, newFolderId),
      ),
    cleanup,
  }
}
