import { Trans } from 'react-i18next'
import { useAuthenticatedUserRedirect } from '@features/auth/hooks/useAuthenticatedUserRedirect'
import Link from '../../../../elements/Link'
import styles from './GuestFooter.module.css'

interface Props {
  msgKey: string
  linkKey: string
  link: string
}

// This component should be refactored (used only on legacy pages)
// https://heybliss.atlassian.net/browse/BLISS-12196

/**
 * @deprecated Refactor this component
 *   https://heybliss.atlassian.net/browse/BLISS-12196
 */
const GuestFooter = ({ msgKey, linkKey, link }: Props) => {
  const { originRedirectUrl } = useAuthenticatedUserRedirect()

  return (
    <div className={styles.Root}>
      <span className={styles.Message}>
        <Trans i18nKey={msgKey} />
      </span>

      <Link
        to={link}
        linkClassName={styles.Link}
        routerState={{ originUrl: originRedirectUrl }}
      >
        <Trans i18nKey={linkKey} />
      </Link>
    </div>
  )
}

export default GuestFooter
