import { ClipboardEventHandler } from 'react'

export const REPLACE_BREAK_LINES_TO_SPACES = /\r?\n|\r/g

// This method is replacing break lines to spaces in the paste event text
export const lineBreaksRemovalFromPasteEventDecorator = (
  onPaste?: ClipboardEventHandler,
): ClipboardEventHandler => {
  return (event) => {
    event.preventDefault()
    try {
      const text = event?.clipboardData?.getData('text') || ''
      const cleanText = text.replace(REPLACE_BREAK_LINES_TO_SPACES, ' ')
      event.clipboardData.setData('text', cleanText)
      document.execCommand('insertText', false, cleanText)

      if (onPaste) {
        onPaste(event)
      }
      return false
    } catch (_) {
      // nothing to do there
      return undefined
    }
  }
}
