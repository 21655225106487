import { useEffect, useMemo, useState } from 'react'
import useLogUsageEvents from '@features/analytics/useLogUsageEvents'
import useRouterParams from '@hooks/useRouterParams'
import useFolder from '@hooks/useFolder'
import { useUpdatesMessages } from '@features/updates/hooks/useUpdatesMessages'

import { Folder, Node, NodeType } from '../../../models'

import usePageNodeTransformer, {
  NodeWithSource,
} from '../../nodes/hooks/usePageNodeTransformer'

interface Props {
  node?: NodeWithSource
  prev?: () => void
  next?: () => void
  currentFolderId: string | undefined
}

const ALLOWED_TYPES_IN_PREVIEW = [
  NodeType.LINK,
  NodeType.IMAGE,
  NodeType.PDF,
  NodeType.TEXT,
  NodeType.PARAGRAPH,
  NodeType.HEADLINE,
  NodeType.VIDEO,
  NodeType.VIDEO_STREAMING,
  NodeType.BUTTON,
  NodeType.PAGE,
  NodeType.AUDIO,
]

const usePreviewNode = ({
  specificNid,
  folder,
  sharedBoardId,
  sharedFolderId,
  messageId,
}: {
  specificNid: string
  folder?: Folder
  sharedBoardId?: string
  sharedFolderId?: string
  messageId?: string
}): Props => {
  const [nid, setNid] = useState(specificNid)
  const [currentFolderId, currentCurrentFolderId] = useState<
    string | undefined
  >(folder?.id)

  let { boardId } = useRouterParams()
  const { folder: srcFolder } = useFolder(boardId, folder?.id)
  if (sharedBoardId && sharedFolderId) {
    boardId = sharedBoardId
  }

  const { viewNode } = useLogUsageEvents()
  const transformPageNode = usePageNodeTransformer()
  const { getMessageById } = useUpdatesMessages()

  const nodesForPreview = messageId
    ? getMessageById(messageId)
        ?.processedContent?.filter((el) => !el.isDeletedOrMoved)
        ?.map((el) => el.node)
    : (srcFolder || folder)?.content

  const nodes =
    nodesForPreview?.filter(({ type }) =>
      ALLOWED_TYPES_IN_PREVIEW.includes(type),
    ) || []
  const nodeSrc = nodes.find((n) => n.id === nid)

  const node = useMemo(
    () => (nodeSrc ? transformPageNode(nodeSrc as Node) : undefined),
    [nodeSrc, transformPageNode],
  )

  const nodeIndex = nodes.findIndex((n) => n.id === nid)
  const hasPrev = nodeIndex > 0
  const hasNext = nodes.length - 1 > nodeIndex

  const setCurrentAttachmentFolderIdIfNeeded = (nodeId: string) => {
    if (!messageId) return

    const newFolder = getMessageById(messageId)?.processedContent?.find(
      (el) => el.node.id === nodeId,
    )?.folderId
    if (newFolder) {
      currentCurrentFolderId(newFolder)
    }
  }

  useEffect(() => {
    setNid(specificNid)
  }, [specificNid])

  const prev = () => {
    if (hasPrev) {
      const newId = nodes[nodeIndex - 1].id
      setNid(newId)
      setCurrentAttachmentFolderIdIfNeeded(newId)

      if (node?.source.type !== NodeType.FOLDER) {
        viewNode(nodes[nodeIndex - 1].id)
      }
    }
  }

  const next = () => {
    if (hasNext) {
      const newId = nodes[nodeIndex + 1].id
      setNid(newId)
      setCurrentAttachmentFolderIdIfNeeded(newId)

      if (node?.source.type !== NodeType.FOLDER) {
        viewNode(nodes[nodeIndex + 1].id)
      }
    }
  }

  return {
    node,
    prev: hasPrev ? prev : undefined,
    next: hasNext ? next : undefined,
    currentFolderId,
  } as const
}

export default usePreviewNode
