import { FC, useCallback, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import clsx from 'clsx'
import { Trans } from 'react-i18next'
import { ButtonNew, PremiumCrownIcon } from 'boards-web-ui'

import usePreviewNode from '@features/nodePreview/hooks/usePreviewNode'
import PreviewPageContent from '@features/nodePreview/components/PreviewPageContent'
import PreviewModal from '@features/templateGallery/components/PreviewModal'
import { TemplateModel } from '@features/templateGallery/models/Template'
import {
  getTemplateIdUpdatedUrl,
  getTemplatePreviewCloseUrl,
} from '@features/templateGallery/helpers/templateUrlHelper'
import { IconWrapper } from '@ui/components/IconWrapper'
import { Folder } from 'app/models'

import styles from './TemplatePreview.module.css'

interface Props {
  nid: string
  hasCrown: boolean
  templates: TemplateModel[]
  onChooseThisTemplateClick: (selectedPage: Folder) => void
  isPagesBoardRoute: boolean
  boardId: string
  folderId: string
}
const TemplatePreview: FC<Props> = ({
  nid,
  hasCrown,
  templates,
  onChooseThisTemplateClick,
  isPagesBoardRoute,
  boardId,
  folderId,
}) => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const folder = {
    id: '',
    icon: '',
    title: '',
    text: '',
    shortcut: '',
    isPage: false,
    content: templates.map((t) => ({ ...t.data, type: 'page' })) as unknown,
  } as Folder
  const { node, prev, next } = usePreviewNode({
    specificNid: nid,
    folder,
  })
  const selectedPage = folder.content.find(
    (t) => t.id === node?.source.id,
  ) as unknown as Folder | undefined

  const handleNext = useCallback(() => {
    if (next) next()
  }, [next])

  const handlePrev = useCallback(() => {
    if (prev) prev()
  }, [prev])

  const handleClose = useCallback(() => {
    navigate(
      getTemplatePreviewCloseUrl({
        searchParams,
        isPagesBoardRoute,
        boardId,
        folderId,
      }),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, searchParams])

  useEffect(() => {
    const bindKeyboardEvents = (e: KeyboardEvent) => {
      if (next && e.key === 'ArrowRight') {
        handleNext()
      }
      if (prev && e.key === 'ArrowLeft') {
        handlePrev()
      }
    }

    document.addEventListener('keyup', bindKeyboardEvents)

    return () => {
      document.removeEventListener('keyup', bindKeyboardEvents)
    }
  }, [next, prev, handleNext, handlePrev])

  useEffect(() => {
    if (selectedPage?.id) {
      navigate(
        getTemplateIdUpdatedUrl({
          templateId: selectedPage.id,
          searchParams,
          isPagesBoardRoute,
          boardId,
          folderId,
        }),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPage?.id])

  if (!selectedPage) return null

  return (
    <PreviewModal
      nid={selectedPage.id}
      folder={folder}
      next={handleNext}
      prev={handlePrev}
      close={handleClose}
      controls={
        <div className={styles.ButtonWrapper}>
          <ButtonNew
            color="primary"
            size="medium"
            startIcon={
              hasCrown && (
                <IconWrapper>
                  <PremiumCrownIcon />
                </IconWrapper>
              )
            }
            onClick={() => onChooseThisTemplateClick(selectedPage)}
          >
            <Trans i18nKey={'page_preview_choose_this_template'} />
          </ButtonNew>
        </div>
      }
    >
      <div className={styles.Root} onClick={(e) => e.stopPropagation()}>
        <div className={clsx(styles.Page)}>
          <PreviewPageContent
            themeId={selectedPage?.themeId}
            nodes={selectedPage?.content}
          />
        </div>
      </div>
    </PreviewModal>
  )
}

export default TemplatePreview
