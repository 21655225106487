import useBoard from '@hooks/useBoard'
import { useContentFromFeedActions } from '@features/updates/hooks/useContentFromFeedActions'
import { useUpdatesEvents } from '@features/analytics/useUpdatesEvents'
import { UpdatesSourceEnum } from '@features/updates/components/UpdatesMessage'
import { useUpdatesApi } from '@features/updates/hooks/useUpdatesApi'
import {
  MessageAttachment,
  Node,
  NodeType,
  UiUpdatesMessage,
} from '../../../models'

export const usePreviewAttachmentClick = ({
  message,
  messageAttachment,
  source,
}: {
  message: UiUpdatesMessage
  messageAttachment: MessageAttachment | null
  source: UpdatesSourceEnum
}) => {
  const { board } = useBoard()
  const { boardId } = useBoard()
  const { markAsRead } = useUpdatesApi()

  const { appMessageContentClickEvent } = useUpdatesEvents()

  const { onPreviewAttachmentClick, onOpenFolder } = useContentFromFeedActions({
    folderId: messageAttachment?.folderId,
  })

  const handlePreviewAttachmentClick = ({ node }: { node: Node }) => {
    if (!messageAttachment) {
      return
    }

    markAsRead(message)

    appMessageContentClickEvent({
      messageId: message.id,
      boardId,
      keyId: node.id,
      isActiveBoard: Boolean(board?.isActive),
      fileType: [node.type],
    })

    if (node.type === NodeType.FOLDER) {
      onOpenFolder({ folderId: node.id, source })
      return
    }

    onPreviewAttachmentClick({ id: node.id, source, messageId: message.id })
  }

  return handlePreviewAttachmentClick
}
