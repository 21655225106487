import { FC } from 'react'
import {
  ContentPickersEnum,
  SelectAction,
  useContentSelection,
} from '@features/pickers'
import { PickerModal } from '@ui/components/PickerModal'

import { ComposeContentDialogBody } from './ComposeContentDialogBody'
import { ComposeContentDialogHeader } from './ComposeContentDialogHeader'
import { ComposeContentDialogFooter } from './ComposeContentDialogFooter'

interface Props {
  onClose: () => void
  action: SelectAction
  maxItemsCount: number
  preselectedNodesIds: string[]
}

export const ComposePickerModal: FC<Props> = ({
  onClose,
  action,
  maxItemsCount,
  preselectedNodesIds,
}) => {
  const {
    nodes,
    clearSelection,
    insertSelection,
    toggleNode,
    showClearMsgIfHasSelectedNodes,
  } = useContentSelection(action)

  return (
    <PickerModal
      onClose={onClose}
      picker={ContentPickersEnum.Compose}
      header={<ComposeContentDialogHeader />}
      footer={
        <ComposeContentDialogFooter
          selectedNodes={nodes.length}
          clearSelection={clearSelection}
          insertSelection={insertSelection}
        />
      }
      onBeforeGoBack={showClearMsgIfHasSelectedNodes}
    >
      <ComposeContentDialogBody
        nodes={nodes}
        toggleNode={toggleNode}
        maxItemsCount={maxItemsCount}
        preselectedNodesIds={preselectedNodesIds}
      />
    </PickerModal>
  )
}
