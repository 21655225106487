import { ChangeEvent, useLayoutEffect, useRef } from 'react'

export const useAdjustHeightMessageTextarea = ({
  onChange,
}: {
  onChange?: (value: string) => void
}) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null)

  function adjustHeight() {
    if (!textAreaRef.current) {
      return
    }

    textAreaRef.current.style.height = 'inherit'
    textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`
  }

  useLayoutEffect(adjustHeight, [textAreaRef])

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (onChange) {
      onChange(e.target.value)
    }
    requestAnimationFrame(adjustHeight)
  }

  return {
    handleChange,
    textAreaRef,
  }
}
