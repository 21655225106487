import { PricePreviewResponse } from '@paddle/paddle-js'
import { getCurrencySymbol } from 'boards-web-ui'

import { Subscription } from 'app/models'
import { TimePeriod } from '@paddle/paddle-js/types/shared/shared'
import { formatNumberWithMaxTwoDecimals } from './price'

export interface ProductData {
  productName: string
  priceId: string
  price: number
  annualPrice: number
  trialPeriod: TimePeriod | null
  subscription: Subscription
}
export interface ProductsData {
  productId: string
  month?: ProductData
  year?: ProductData
}
export interface ProductsResponse {
  currencySymbol: string
  currencyCode: string
  items: ProductData[]
}

const calculatePrice = (value: string, divider: number) => {
  const parsedValue = Number.parseFloat(value)
  return formatNumberWithMaxTwoDecimals(parsedValue / 100 / divider)
}

const transformformPricePreview = (
  subscriptions: Subscription[],
  pricePreview?: PricePreviewResponse,
): ProductsResponse | null => {
  if (!pricePreview) return null

  const products = pricePreview.data?.details.lineItems
    .reduce<ProductData[]>((acc, item) => {
      if (!item?.price?.billingCycle?.interval) {
        return acc
      }
      const subscription = subscriptions.find(
        (s) => s.productId === item.price.id,
      )

      if (!subscription) {
        return acc
      }

      const { interval: subscriptionInterval, frequency } =
        item.price.billingCycle

      const annualPriceMultiplier =
        subscriptionInterval === 'year' ? 1 : 12 / frequency

      const productData: ProductData = {
        subscription,
        productName: item.product.name,
        priceId: item.price.id,
        price: calculatePrice(
          item.totals.total,
          subscriptionInterval === 'year' ? 12 : frequency, // assume that we have only month and year billingCycle
        ),
        annualPrice: formatNumberWithMaxTwoDecimals(
          (Number.parseFloat(item.totals.total) / 100) *
            annualPriceMultiplier,
        ),
        trialPeriod: item.price.trialPeriod,
      }

      return [...acc, productData]
    }, [])
    .sort((a, b) => {
      const { period: aPeriod, numberOfPeriods: aNumberOfPeriods = 1 } =
        a.subscription
      const { period: bPeriod, numberOfPeriods: bNumberOfPeriods = 1 } =
        b.subscription

      if (aPeriod === bPeriod) {
        return aNumberOfPeriods - bNumberOfPeriods
      }

      if (aPeriod === 'month' && bPeriod === 'year') {
        return -1
      }

      if (aPeriod === 'year' && bPeriod === 'month') {
        return 1
      }

      return 0
    }) // will return a sorter array by term: month, 3 month, year

  return {
    currencyCode: pricePreview.data.currencyCode,
    currencySymbol: getCurrencySymbol(pricePreview.data.currencyCode),
    items: products,
  }
}

export default transformformPricePreview
