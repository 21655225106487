import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useAuth } from '@hooks/useAuth'

import { FirebaseError } from '@firebase/util'
import { EmailPasswordSignIn } from '_firebase/models/auth'
import { useAuthenticatedUserRedirect } from '@features/auth/hooks/useAuthenticatedUserRedirect'
import { firebaseEventsAsyncActions } from '../../../../actions'
import useIsOnline from '../../../../hooks/useIsOnline'

// eslint-disable-next-line
export const useSignIn = (setError: any) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { signIn } = useAuth()
  const [submitting, setSubmitting] = useState(false)
  const isOnline = useIsOnline()

  const { onSignInRedirect } = useAuthenticatedUserRedirect()

  useEffect(() => {
    dispatch(
      firebaseEventsAsyncActions.logEvent('app_screen_view', {
        screen: 'sign_in',
      }),
    )
  }, [dispatch])

  const onError = (errorField: string, message: string) => {
    setError(errorField, {
      type: 'server',
      message,
    })
  }

  const onSignIn = async (data: EmailPasswordSignIn) => {
    setSubmitting(true)
    dispatch(
      firebaseEventsAsyncActions.logEvent('app_button_click', {
        action: 'sign_in',
      }),
    )

    try {
      await signIn(data)
      dispatch(firebaseEventsAsyncActions.logEvent('app_sign_in'))
      onSignInRedirect()
    } catch (error) {
      if (!isOnline) {
        setError('password', t('optimistic_ui_failed'))
        return
      }

      if (error instanceof FirebaseError) {
        onError('password', t([`errors.${error.code}`, 'errors.default']))
        dispatch(
          firebaseEventsAsyncActions.logEvent('app_error', {
            type: 'sign_in_failed',
            code: error.code,
          }),
        )
      }

      setSubmitting(false)
    }
  }

  return {
    submitting,
    onError,
    onSignIn,
  }
}
