import useNodeActions from '@features/board/hooks/useNodeActions'
import useFolder from '@hooks/useFolder'
import useRouterParams from '@hooks/useRouterParams'
import { useRef } from 'react'
import { SourceType } from '@features/analytics/useFolderEvents'

export const useContentFromFeedActions = (params: { folderId?: string }) => {
  const { boardId } = useRouterParams()
  const timer = useRef<number>(0)
  const { folder } = useFolder(boardId, params.folderId)

  const { previewNode, openFolder } = useNodeActions({
    folder,
  })

  const onOpenFolder = ({
    folderId,
    anchorId,
    source,
  }: {
    folderId: string
    anchorId?: string
    source?: SourceType
  }) => {
    openFolder({ boardId, folderId, source })

    timer.current = window.setTimeout(() => {
      if (anchorId) {
        const element = document.getElementById(anchorId)
        if (!element) {
          return
        }

        const offset = 50
        const heightValue = element.clientHeight
        element.style.height = `${heightValue + offset}px`
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
          inline: 'nearest',
        })
        element.style.height = `${heightValue}px`
      }

      clearTimeout(timer.current)
    }, 400)
  }

  const onPreviewAttachmentClick = ({
    id,
    source,
    messageId,
  }: {
    id: string
    source?: SourceType
    messageId: string
  }) => {
    previewNode({ nodeId: id, hideParents: true, source, messageId })
  }

  const onClearTimerAfterScroll = () => {
    timer.current = -1
    clearTimeout(timer.current)
  }

  return {
    onPreviewAttachmentClick,
    onOpenFolder,
    onClearTimerAfterScroll,
  }
}
