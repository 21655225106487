import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'

import useSubscriptionPlans from '@features/payments/hooks/useSubscriptionPlans'
import usePaywallProducts from '@features/payments/hooks/usePaywallProducts'
import usePaddleAPI from '@features/payments/hooks/usePaddleAPI'
import usePaywallEvents from '@features/analytics/usePaywallEvents'
import useHandleEventCallback from '@features/payments/hooks/useHandleEventCallback'
import { ProductData } from '@features/payments/utils/productTransformer'
import useStartProfileListener from '@hooks/useStartProfileListener'
import useFetchAvailableSubscriptions from '@hooks/useFetchAvailableSubscriptions'
import useMarathonCampaign from '@features/payments/hooks/useMarathonCampaign'
import { EDITOR } from 'constants/routes'
import { QUERY_PARAMS } from 'app/constants/routes'

const usePrivatePaywall = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [searchParams] = useSearchParams()
  const queryParamPriceId = searchParams.get(QUERY_PARAMS.priceId)
  const campaignId = searchParams.get(QUERY_PARAMS.campaign) || undefined
  const isFromSource = Boolean(searchParams.get(QUERY_PARAMS.fromSource))

  const { current, paddlePriceIds, status, plans } = useSubscriptionPlans()
  const handleEventCallback = useHandleEventCallback(campaignId, isFromSource)
  const { startCheckout, updateCheckout } = usePaddleAPI(handleEventCallback)
  const { products, subscriptions } = usePaywallProducts(paddlePriceIds)
  const { paywallView, paywallTabClickEvent, paywallSubscribeClickEvent } =
    usePaywallEvents()
  const { isMarathonCampaign } = useMarathonCampaign(campaignId)

  useStartProfileListener()
  useFetchAvailableSubscriptions(campaignId)

  const isSubscriptionCanceled = !!current?.scheduledCancel
  const isSubscribedInAnotherStore = ['AppStore', 'GooglePlay'].includes(
    current?.source || '',
  )
  const isQueryPriceIdInPlans =
    queryParamPriceId && paddlePriceIds.includes(queryParamPriceId)
  const isSubscribedToAvailablePlanFrom3rdSource = Boolean(
    plans.find(({ id }) => id === current?.plan),
  )
  const querySubscription = subscriptions.find(
    (s) => s.productId === queryParamPriceId,
  )

  const alreadySubscribedInAnotherStoreText = useMemo(() => {
    if (
      isSubscribedToAvailablePlanFrom3rdSource &&
      current?.productId === queryParamPriceId
    ) {
      return undefined
    }
    if (current?.isTrial) return t('desktop_plan_on_trial')
    if (current?.source === 'AppStore')
      return t('paywall_already_subscribed_ios')
    if (current?.source === 'GooglePlay')
      return t('paywall_already_subscribed_android')
    return undefined
  }, [
    isSubscribedToAvailablePlanFrom3rdSource,
    current?.productId,
    current?.isTrial,
    current?.source,
    queryParamPriceId,
    t,
  ])

  const isToOpenCheckoutDialog = useMemo(() => {
    return (
      status === 'loaded' &&
      isQueryPriceIdInPlans &&
      !isMarathonCampaign &&
      !isSubscribedInAnotherStore &&
      !isSubscriptionCanceled &&
      !current?.plan
    )
  }, [
    status,
    isMarathonCampaign,
    isQueryPriceIdInPlans,
    isSubscribedInAnotherStore,
    isSubscriptionCanceled,
    current,
  ])

  useEffect(() => {
    paywallView(campaignId)
  }, [campaignId, paywallView])

  useEffect(() => {
    if (isToOpenCheckoutDialog && querySubscription?.productId) {
      startCheckout(
        querySubscription?.productId,
        querySubscription?.productDiscountId,
      )
    }
  }, [
    isToOpenCheckoutDialog,
    querySubscription?.productId,
    querySubscription?.productDiscountId,
    startCheckout,
  ])

  const handleSubscribePlanClick = async (
    plan: ProductData,
    isCurrentSubscribedPlan: boolean,
  ) => {
    if (!isCurrentSubscribedPlan || !isSubscriptionCanceled) {
      return
    }

    const priceId = plan.subscription?.offer?.priceId || plan.priceId
    paywallSubscribeClickEvent(plan.productName, priceId, campaignId)

    if (current && !current.isReverseTrial) {
      await updateCheckout(priceId)
      navigate(EDITOR)
      return
    }

    startCheckout(priceId, plan?.subscription?.productDiscountId)
  }

  return {
    alreadySubscribedInAnotherStoreText,
    campaignId,
    current,
    isFromSource,
    isSubscribedToAvailablePlanFrom3rdSource,
    products,
    handlePlanSwipe: paywallTabClickEvent,
    handleSubscribePlanClick,
  }
}

export default usePrivatePaywall
