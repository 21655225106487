import { useEffect } from 'react'
import { theme, useDialog } from 'boards-web-ui'
import { ComposePickerModal } from '@features/pickers/composePicker/ComposePickerModal'
import { Node } from '../../../models'

interface SelectActionProps {
  boardId: string
  nodes: Node[]
}

type SelectAction = (props: SelectActionProps) => void

type Props = {
  action: SelectAction
  initiallyOpenPicker: boolean
  maxItemsCount: number
  preselectedNodesIds: string[]
}

export const useComposePicker = ({
  action,
  initiallyOpenPicker,
  maxItemsCount,
  preselectedNodesIds,
}: Props) => {
  const { open, close } = useDialog()

  const openSourcePicker = () => {
    open(
      <ComposePickerModal
        onClose={close}
        action={action}
        maxItemsCount={maxItemsCount}
        preselectedNodesIds={preselectedNodesIds}
      />,
      {
        fullScreen: true,
        closeOnOverlayClick: true,
        overlayBackgroundColor: theme.theme.colors.dialogBackgroundDark50,
        withFullScreenDialogContainer: false,
      },
    )
  }

  useEffect(() => {
    if (initiallyOpenPicker) {
      openSourcePicker()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initiallyOpenPicker])

  return {
    selectContent: openSourcePicker,
  }
}
