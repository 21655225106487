import { FC, useEffect } from 'react'
import FolderSelection from '@features/pickers/components/FolderSelection'
import { ContentPickersEnum, useContentNavigation } from '@features/pickers'
import useRouterParams from '@hooks/useRouterParams'
import { useDispatch } from 'react-redux'
import { Node as NodeModel } from '../../../../models'
import { boardActions } from '../../../../../actions'

interface Props {
  nodes: NodeModel[]
  toggleNode: (node: NodeModel) => void
  maxItemsCount: number
  preselectedNodesIds: string[]
}

export const ComposeContentDialogBody: FC<Props> = ({
  nodes,
  toggleNode,
  maxItemsCount,
  preselectedNodesIds,
}) => {
  const {
    pickerBoardId: boardId,
    pickerFolderId: folderId,
    isPage,
    selectFolder,
  } = useContentNavigation(ContentPickersEnum.Compose)

  const dispatch = useDispatch()

  const { boardId: currentBoardId, folderId: currentFolderId } =
    useRouterParams()

  const shouldUpdateComposePath = currentBoardId !== boardId

  useEffect(() => {
    if (!shouldUpdateComposePath) {
      return
    }

    dispatch(
      boardActions.setFolderPathAction(
        ContentPickersEnum.Compose,
        currentBoardId,
        currentFolderId,
      ),
    )
  }, [currentBoardId, currentFolderId, dispatch, shouldUpdateComposePath])

  return boardId && folderId && !isPage ? (
    <FolderSelection
      nodes={nodes}
      boardId={boardId}
      folderId={folderId}
      selectFolder={(fid, node) => {
        if (nodes.length) {
          toggleNode(node)
          return
        }

        selectFolder(fid)
      }}
      toggleNode={toggleNode}
      picker={ContentPickersEnum.Compose}
      maxItemsCount={maxItemsCount}
      preselectedNodesIds={preselectedNodesIds}
    />
  ) : null
}
