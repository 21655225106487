import { KeyboardEventHandler } from 'react'

const DEFAULT_BLOCKED_KEYS: string[] = ['Enter', 'Tab']

export const blockKeyboardsKeysDecorator = (
  onKeyDown?: KeyboardEventHandler,
  blockedKeys: Array<string> = DEFAULT_BLOCKED_KEYS,
): KeyboardEventHandler => {
  return (event) => {
    if (blockedKeys.includes(event.key)) {
      event.preventDefault()
      return false
    }

    return onKeyDown ? onKeyDown(event) : undefined
  }
}
