import React, { FC, memo, useEffect } from 'react'

import { BackIcon, ButtonIcon, Tooltip, TooltipContent } from 'boards-web-ui'
import { IconWrapper } from '@ui/components/IconWrapper'
import { Trans } from 'react-i18next'
import { useUpdatesEvents } from '../../analytics/useUpdatesEvents'

import { useUpdatesMessages } from '../hooks/useUpdatesMessages'
import { useUpdatesMessage } from '../hooks/useUpdatesMessage'

import UpdatesMessage, { UpdatesSourceEnum } from './UpdatesMessage'
import UpdatesMessageMenu from './UpdatesMessageMenu'
import styles from './UpdatesSelected.module.css'
import { MessageAttachment } from '../../../models'

type Props = {
  onClose: () => void
  onDelete: () => void
}

const UpdatesSelected: FC<Props> = ({ onClose, onDelete }) => {
  const { updateView } = useUpdatesEvents()
  const { selectedMessage, viewOnly } = useUpdatesMessages()
  const { canRemove, remove, unselect } = useUpdatesMessage(
    selectedMessage ? selectedMessage.id : null,
  )

  useEffect(() => {
    if (selectedMessage) {
      const messageAttachment: MessageAttachment[] | null =
        selectedMessage.processedContent

      const attachedTypes =
        messageAttachment?.map((attachment) => attachment?.node?.type) || []
      const attachedKeyId =
        messageAttachment?.length === 1
          ? messageAttachment?.[0]?.node?.id
          : null

      updateView({
        updateId: selectedMessage.id,
        boardId: selectedMessage.node,
        unreadState: selectedMessage.unread,
        isFileAttached: messageAttachment !== null,
        fileType: attachedTypes,
        attachedKeyId,
      })
    }
  }, [updateView, selectedMessage])

  if (!selectedMessage) {
    return null
  }

  return (
    <div className={styles.Root}>
      <div className={styles.Controls}>
        <Tooltip
          data={
            <TooltipContent>
              <Trans i18nKey={'action_back'} />
            </TooltipContent>
          }
        >
          <ButtonIcon
            size={38}
            round
            variant="gray"
            onClick={() => {
              unselect()
              onClose()
            }}
          >
            <IconWrapper>
              <BackIcon />
            </IconWrapper>
          </ButtonIcon>
        </Tooltip>

        {!viewOnly && canRemove && (
          <UpdatesMessageMenu
            onDelete={() => {
              remove()
              onDelete()
            }}
          />
        )}
      </div>

      <UpdatesMessage
        message={selectedMessage}
        source={UpdatesSourceEnum.Screen}
      />
    </div>
  )
}

export default memo(UpdatesSelected)
